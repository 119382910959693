import { useState, Fragment } from "react"
import { visuallyHidden } from '@mui/utils'
import * as moment from "moment"
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { stableSort, getComparator } from '../utils/Helper.js'
import { Chip, Collapse, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Box } from '@mui/material'

function Row({company}) {

    const [open, setOpen] = useState(false)

    const handleClick = () => {}

    return(
        <Fragment key={company.id}>
            <TableRow hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{company.name}</strong>
                </TableCell>
                <TableCell>{company.status ? "Active" : "Inactive" }</TableCell>
                <TableCell>{moment.unix(company.ctime).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 120, paddingRight: 120}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{marginBottom:10}}>
                        {company.factories.map((factory, index) => {
                            return (<Chip key={index} label={factory} variant="outlined" onClick={handleClick} sx={{marginRight:1,marginBottom:1}} />)
                        })}               
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )

}

export default function CompanyDesktopTableList({companies}) {

    const [orderBy, setOrderBy] = useState('name')
    const [order, setOrder] = useState('asc')

    const desktopHeadCells = [
        { id: "collapse", numeric: false, disablePadding: false, label: "" },
        { id: "name", numeric: false, disablePadding: false, label: "Name" },
        { id: "status", numeric: false, disablePadding: false, label: "Status" },
        { id: "ctime", numeric: false, disablePadding: false, label: "Joined" },
    ]

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'
    
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }
    
    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {desktopHeadCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}>
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(companies, getComparator(order, orderBy))
                        .map((row, index) => {
                            return(<Row key={index} company={row} />)
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}