import React, { useEffect, useState } from 'react'
import useMqtt from '../../hooks/useMqtt'
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import TopAppBar from '../../ui/TopAppBar'
import Title from '../../ui/Title'
import Subtitle from '../../ui/Subtitle'
import SensorDesktopTableList from '../../ui/SensorDesktopTable'
import SensorMobileTableList from '../../ui/SensorMobileTable'
import { BrowserView, MobileView } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton, Typography, Grid, Container, Paper, LinearProgress } from '@mui/material'
import { getSensorList, selectSensorList } from '../../redux/features/sensor/sensorSlice'
import { selectToken, selectAuthFactoryId } from '../../redux/features/security/authenticationSlice'
import { getUserInfo, selectFactory, selectCompany, selectSchema } from '../../redux/features/profile/profileSlice'
import { getNotificationList, selectNotifications, selectUnreadCount } from '../../redux/features/notification/notificationSlice'

export default function Reading() {

    const dispatch = useDispatch()
    const { mqttSubscribe, mqttConnect, isConnected, payload } = useMqtt()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [readings, setReadings] = useState([])
    const [logtime, setLogtime] = useState("")
    const [value, setValue] = useState(0)
    const [hasUserInfoLoaded, setHasUserInfoLoaded] = useState(false)
    const [hasSensorListLoaded, setHasSensorListLoaded] = useState(false)
    const [notificationsLoaded, setNotificationsLoaded] = useState(false)

    const notificationsUnreadCount = useSelector(selectUnreadCount)
    const notifications = useSelector(selectNotifications)
    const token = useSelector(selectToken)
    const factoryId = useSelector(selectAuthFactoryId)
    const factory = useSelector(selectFactory)
    const company = useSelector(selectCompany)
    const sensors = useSelector(selectSensorList)
    const schema = useSelector(selectSchema)

    useEffect(() => {

        if (!hasUserInfoLoaded && token !== '') {

            dispatch(getUserInfo(token))
            setHasUserInfoLoaded(true)            

        }

        if (!hasSensorListLoaded && token !== '') {
                
            dispatch(getSensorList(token, factoryId, factoryId))
            setHasSensorListLoaded(true)
            setIsLoading(false)

        }

        if (!notificationsLoaded && token !== '') {

            dispatch(getNotificationList(token, factoryId))
            setNotificationsLoaded(true)

        }

    }, [
        dispatch,
        hasUserInfoLoaded,
        hasSensorListLoaded,
        token,
        factoryId,
        schema,
        sensors,
        notificationsLoaded
    ])

    useEffect(() => {

        if (isConnected) {
            var topic = "sensors/" + schema + "/all"
            mqttSubscribe(topic)
        }

    }, [isConnected, schema, mqttSubscribe])

    useEffect(() => {

        if (payload.message) {        

            var obj = JSON.parse(payload.message.toString())
            var tmp = []
            var lt = []

            for (var i = 0; i < obj.length; i++) {
                
                for (var j = 0; j < sensors.length; j++) {
                    
                    if (obj[i].name === sensors[j].name) {

                        tmp.splice(j, 0, obj[i].calculated)
                        lt.splice(j, 0, obj[i].logtime)

                    }

                }

            }

            setReadings(tmp)
            setLogtime(lt)
            setIsLoading(false)

        }

    }, [payload, sensors])

    useEffect(() => {
        window.scrollTo(0, 0)
        mqttConnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showCustomerInfo = () => (
        <Grid item xs={12} md={12} lg={12}>
            {isLoading ? <Skeleton variant="rounded" sx={{marginBottom:1}} /> : <Title>{factory}</Title> }            
            {isLoading ? <Skeleton variant="rounded" sx={{marginBottom:3}} /> : <Subtitle>{company}</Subtitle> }
        </Grid>
    )

    const currentReading = () => (
        <Grid container>
            {isLoading === false ? sensors.map((key, i) => (
            <Grid key={i} item xs={12} md={6} lg={6} sx={{padding:1}}>
                <Paper sx={{padding:1}}>
                    <Subtitle>{sensors[i].name}</Subtitle>
                    <Typography color={readings[i] > sensors[i].max_limit ? "orangered" : "forestgreen"} component="p" variant="h4">
                        {readings[i]} {sensors[i].unit}
                    </Typography>
                    <Typography color="textSecondary" component="span" variant="body2">
                        {logtime[i]}
                    </Typography>
                </Paper>
            </Grid>
            )) :
            <React.Fragment>
            <Grid item xs={12} md={6} lg={6}>
                <Skeleton variant="rounded" height={100} sx={{marginBottom:1}} />
            </Grid> 
            <Grid item xs={12} md={6} lg={6}>
                <Skeleton variant="rounded" height={100} sx={{marginBottom:3}} />
            </Grid> 
            </React.Fragment> 
            }
        </Grid>
    )

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const handleChange = (event, newValue) => setValue(newValue)

    return (
        <>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <div>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    updateReadNotifications={true}
                    showNotification={true}
                    unreadCount={notificationsUnreadCount}
                    notifications={notifications}
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="md" style={{marginTop:80}}>

                    {/* Display the customer's factory & company name. */}
                    {showCustomerInfo()}

                    {/* Show real-time sensors readings. */}
                    {currentReading()}

                    {isLoading ? 
                        <Skeleton height={50} variant="rounded" sx={{marginBottom:2}} /> : 
                        <Typography sx={{marginTop:4,marginBottom:2}} variant="h5" component="h5">List of all sensor</Typography>
                    }
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <BrowserView>                
                            {isLoading ?
                            <Skeleton variant="rounded" height={200} /> :                
                            <SensorDesktopTableList sensors={sensors} />                
                            }
                            </BrowserView>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <MobileView>
                                {isLoading ?
                                <Skeleton variant="rounded" height={200} /> :
                                <SensorMobileTableList 
                                    sensors={sensors}
                                    sensorDisplayValue={value}
                                    handleSensorChange={handleChange} />
                                }
                            </MobileView>
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </div>

        </>            
    )

}
