import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const datesCountSlice = createSlice({
    name: "datesCount",
    initialState: {
        isLoading  : false,
        datesCount : [],
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setDatesCount: (state, action) => {
            state.datesCount = action.payload
        },
    }
})

export const {
    startLoading,
    stopLoading,
    setDatesCount,
} = datesCountSlice.actions

export const getDatesCountList = (token, factoryId) => async (dispatch) => {

    dispatch(startLoading())

    var getDatesCount = 
        apiGateway.get_dates_count(token, factoryId)

    Promise.all([getDatesCount])
        .then((values) => {

            const availableDates = values[0]["availableDates"]
            dispatch(setDatesCount(availableDates))
            dispatch(stopLoading())

        })

}

export const selectDatesCountList = (state) => state.datesCount.datesCount
export const selectSelectedDate = (state) => state.datesCount.selectedDate
export const selectIsDatesCountLoading = (state) => state.datesCount.isLoading

export default datesCountSlice.reducer