import { useEffect, useState } from 'react'
import PinInput from "react-pin-input"
import FrontTopAppBar from '../../ui/FrontTopAppBar'
import Footer from '../../ui/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { Alert, AlertTitle, Button, LinearProgress, Box, Grid } from '@mui/material'
import { ALERT_ERROR, ALERT_WARNING, PASSWORD_EXPIRED_MSG } from '../../utils/Constants'
import { selectPasswordErrorMsg, setPasswordError, setPasswordErrorMsg, selectOtpError, setOtpError } from '../../redux/features/security/authenticationSlice';

export default function OTP() {

    const [pin, setPin] = useState("")
    const [email, setEmail] = useState("")
    const [buttonDisable, setButtonDisable] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [otpCompleted, setOtpCompleted] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const dispatch = useDispatch()
    const params = useLocation()
    const passwordErrorMsg = useSelector(selectPasswordErrorMsg)    
    const otpError = useSelector(selectOtpError)

    useEffect(() => {

        setEmail(params.state.email)

        if (submitted) {
            
            dispatch(setOtpError(false))
            dispatch(setPasswordError(false))
            dispatch(setPasswordErrorMsg(""))

            setIsLoading(true)
            setOtpCompleted(true)
            
        }

    }, [
        submitted, 
        params.state.email,
        otpError,
        dispatch
    ])

    const onSubmit = () => setSubmitted(true)

    const onPinChanged = () => {

        if (getPinNumber().length === 6) {
            setButtonDisable(false)
        } else {
            setButtonDisable(true)
        }

    }

    const onPinChangedCompleted = () => setButtonDisable(false)

    const alert = () => (
        <Alert variant="outlined" severity={otpError ? ALERT_ERROR : ALERT_WARNING}>
            <AlertTitle><strong>{passwordErrorMsg}</strong></AlertTitle>
            {PASSWORD_EXPIRED_MSG}
        </Alert>
    )

    const getPinNumber = () => {
        
        return pin.values[0] + 
            pin.values[1] + 
            pin.values[2] + 
            pin.values[3] + 
            pin.values[4] + 
            pin.values[5]
    }

    if (otpCompleted) {

        if (pin !== null) {

            const params = {email:email, pin:getPinNumber()}
            return <Navigate to={"/reset"} state={params} />
    
        }

    } else {

        return (
            <>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}
                <FrontTopAppBar />
                <Grid container sx={{marginTop:8,marginBottom:10}}>                
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} elevation={6} sx={{padding:2}}>
                        {alert()}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>                
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} elevation={6} sx={{padding:2}}>
                        <Box sx={{paddingBottom:4}}>
                            <form noValidate>
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    focus
                                    ref={p => (setPin(p))}
                                    type="numeric"
                                    inputMode="number"
                                    style={{
                                        'marginTop':'10px',
                                        'marginBottom':'50px',
                                        'textAlign':'center'
                                    }}
                                    inputStyle={{
                                        'fontSize':'2em',
                                        'width':'48px',
                                        'height':'55px',
                                        'border':'1px solid #CCCCCC',
                                        'borderRadius':'6px',
                                        'borderShadow':'none',
                                        'margin':'0 2px'
                                    }}
                                    onChange={onPinChanged}
                                    onComplete={onPinChangedCompleted} />
                            </form>
                            <Button 
                                onClick={onSubmit}
                                fullWidth 
                                variant='outlined' 
                                size='large'
                                disabled={buttonDisable}>
                                Confirm OTP
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                </Grid>
                <Footer />
            </>
        )
    }

}