import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Login from './features/login/Login'
import OTP from './features/otp/OTP'
import Reset from './features/reset/Reset'
import Factory from './features/factory/Factory'
import FactoryDetails from './features/factory/FactoryDetails'
import Reading from './features/reading/Reading'
import Daily from './features/daily/Daily'
import Monthly from './features/monthly/Monthly'
import Yearly from './features/yearly/Yearly'
import Profile from './features/profile/Profile'
import Sensor from './features/sensor/Sensor'
import Company from './features/company/Company'
import Customer from './features/customer/Customer'
//import Distributor from './features/distributor/Distributor'
import FAQ from './page/FAQ'
import PrivacyPolicy from './page/PrivacyPolicy'
import TermsConditions from './page/TermsConditions'
// import Dashboard from './features/dashboard/Dashboard'

import ReactGA from 'react-ga'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

// Setup AWS Amplify settings.
// We only need to declare
// this one time, especially here.
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const TRACKING_ID = "UA-60169948-1"
ReactGA.initialize(TRACKING_ID, { testMode: process.env.NODE_ENV === 'test' })

const theme = createTheme({
    palette: {
        primary: {
            main: '#0099D9',
        },
        secondary: {
            main: '#D94000',
        },
        fubar: {
            main: '#ffffff'
        },
        background: {
            default: '#4c49490a'
        }
    },
})

const router = createBrowserRouter([
    {
        path: '/',
        children: [
            { path: "/", element: <Login /> },
            { path: "/faq", element: <FAQ /> },
            { path: "/privacy-policy", element: <PrivacyPolicy /> },
            { path: "/terms-and-conditions", element: <TermsConditions /> },
            { path: "/otp", element: <OTP /> },
            { path: "/reset", element: <Reset /> },
            // { path: "/admin/dashboard", element: <Dashboard /> },
            { path: "/admin/factory", element: <Factory /> },
            { path: "/admin/factory/details", element: <FactoryDetails /> },
            { path: "/admin/sensor", element: <Sensor /> },
            { path: "/admin/company", element: <Company /> },
            { path: "/admin/customer", element: <Customer /> },
            //{ path: "/admin/distributor", element: <Distributor /> },
            { path: "/distributor/factory", element: <Factory /> },
            { path: "/distributor/factory/details", element: <FactoryDetails /> },
            { path: "/distributor/sensor", element: <Sensor /> },
            { path: "/reading", element: <Reading /> },
            { path: "/data/daily", element: <Daily /> },
            { path: "/data/monthly", element: <Monthly /> },
            { path: "/data/yearly", element: <Yearly /> },
            { path: "/profile", element: <Profile /> },            
        ]
    }
])

const App = () => (
    <ThemeProvider theme={theme}>
        <div>
            <CssBaseline />
            <main>      
                <RouterProvider router={router} />
            </main>
        </div>
    </ThemeProvider>
)

export default App
