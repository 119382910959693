import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import useMqtt from '../../hooks/useMqtt'
import Footer from '../../ui/Footer'
import TopAppBar from '../../ui/TopAppBar'
import TabPanel from '../../ui/TabPanel'
import LeftDrawer from '../../ui/LeftDrawer'
import Title from '../../ui/Title'
import Subtitle from '../../ui/Subtitle'
import ReadingsChart from '../../ui/ReadingsChart'
import CanvasJSReact from '@canvasjs/react-charts'
import { LINECHART_COLORS } from '../../utils/Constants'
import SensorDesktopTableList from '../../ui/SensorDesktopTable'
import SensorMobileTableList from '../../ui/SensorMobileTable'
import ControlChartsDesktopTableList from '../../ui/ControlChartsDesktopTableList'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { selectAuthFactoryId, selectToken } from '../../redux/features/security/authenticationSlice'
import { getSensorList, selectSensorList } from '../../redux/features/sensor/sensorSlice'
import { getHourlyDataList, selectHourlyDataList, selectHourlyCcDataList, selectHourlyDataLoading, selectCsvDataList } from '../../redux/features/hourly/hourlyDataSlice'
import { getDailyDataList, selectDailyDataList, selectDailyDataLoading } from '../../redux/features/daily/dailyDataSlice'
import { getMonthlyDataList, selectMonthlyDataList, selectMonthlyDataLoading } from '../../redux/features/monthly/monthlyDataSlice'
import { getYearlyDataList, selectYearlyDataList, selectYearlyDataLoading } from '../../redux/features/yearly/yearlyDataSlice'
import { selectIsDatesCountLoading, getDatesCountList, selectDatesCountList } from '../../redux/features/datesCount/datesCountSlice'
import { getNotificationList, selectNotifications, selectUnreadCount } from '../../redux/features/notification/notificationSlice'
import { CircularProgress, Skeleton, Box, LinearProgress, Divider, Container, Typography, Grid, Tab, Tabs, Paper, Button } from '@mui/material'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

export default function FactoryDetails() {

    const dispatch = useDispatch()
    const params = useLocation()    
    const { mqttSubscribe, mqttConnect, isConnected, payload } = useMqtt()

    const [open, setOpen] = useState(false)
    const [factoryId, setFactoryId] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [isMqttConnected, setIsMqttConnected] = useState(false)

    const [factoryDetails, setFactoryDetails] = useState({})
    const [showDateChooser, setShowDateChooser] = useState(false)
    const [showMonthChooser, setShowMonthChooser] = useState(false)
    const [showYearChooser, setShowYearChooser] = useState(false)
    const [showNotification, setShowNotification] = useState(true)
    const [reloadDaily, setReloadDaily] = useState(true)
    const [reloadMonthly, setReloadMonthly] = useState(true)
    const [reloadYearly, setReloadYearly] = useState(true)
    const [value, setValue] = useState(0)
    const [sensorDisplayValue, setSensorDisplayValue] = useState(0)
    const [readings, setReadings] = useState([])
    const [logtime, setLogtime] = useState("")

    const [notificationsLoaded, setNotificationsLoaded] = useState(false)
    const [sensorsLoaded, setSensorsLoaded] = useState(false)
    const [availableDatesLoaded, setAvailableDatesLoaded] = useState(false)
    const [readingsLoaded, setReadingsLoaded] = useState(false)

    const notificationsUnreadCount = useSelector(selectUnreadCount)
    const notifications = useSelector(selectNotifications)

    const authFactoryId = useSelector(selectAuthFactoryId)
    const sensors = useSelector(selectSensorList)
    const token = useSelector(selectToken)

    const availableDates = useSelector(selectDatesCountList)
    const [selectedDate, setSelectedDate] = useState('')
    const isDatesCountLoading = useSelector(selectIsDatesCountLoading)
    const csvData = useSelector(selectCsvDataList)
    const hourlyData = useSelector(selectHourlyDataList)
    const hourlyCcData = useSelector(selectHourlyCcDataList)
    const dailyData = useSelector(selectDailyDataList)
    const monthlyData = useSelector(selectMonthlyDataList)
    const yearlyData = useSelector(selectYearlyDataList)

    const isLoadingHourlyData = useSelector(selectHourlyDataLoading)
    const isLoadingDailyData = useSelector(selectDailyDataLoading)
    const isLoadingMonthlyData = useSelector(selectMonthlyDataLoading)
    const isLoadingYearlyData = useSelector(selectYearlyDataLoading)      

    useEffect(() => {            

        if (params.state !== null) {

            if (params.state.factory !== null && token !== '') {

                const givenFactoryId = params.state.factory.id
                setFactoryId(givenFactoryId)
                setFactoryDetails(params.state.factory)

                if (!notificationsLoaded) {            
                    dispatch(getNotificationList(token, givenFactoryId))
                    setNotificationsLoaded(true)
                }

                if (!sensorsLoaded) {
                    dispatch(getSensorList(token, givenFactoryId))
                    setSensorsLoaded(true)
                }

                if (!availableDatesLoaded) {
                    dispatch(getDatesCountList(token, givenFactoryId))
                    setAvailableDatesLoaded(true)
                }

                if (!readingsLoaded) {
                    setReadingsLoaded(true)
                }
        
            }

            if (sensors.length > 0 && 
                availableDates.length > 0 && 
                (dailyData.length > 0 || hourlyData.length > 0 || csvData.length > 0) && 
                monthlyData.length > 0 && 
                yearlyData.length > 0) {
                setIsLoading(false)
            }

        }

    }, [
        params.state, 
        notificationsLoaded,
        dispatch,
        factoryId,
        authFactoryId,
        sensorsLoaded,
        sensors,
        availableDates,
        availableDatesLoaded,
        readingsLoaded,
        token,
        hourlyData.length,
        dailyData.length,
        monthlyData.length,
        yearlyData.length,
        csvData.length
    ])

    useEffect(() => {

        if (availableDates) {
            setSelectedDate(availableDates[availableDates.length - 1])    
        }
        
    }, [availableDates])

    useEffect(() => {

        if (reloadDaily && !isDatesCountLoading && availableDates.length > 0 && selectedDate !== '') {

            if (isMobile) {
                dispatch(getDailyDataList(token, factoryId, selectedDate))
            } else {
                dispatch(getHourlyDataList(token, factoryId, selectedDate))
            }

            setReloadDaily(false)

        }

        if (reloadMonthly && !isDatesCountLoading && availableDates.length > 0 && selectedDate !== '') {
            dispatch(getMonthlyDataList(token, factoryId, selectedDate))
            setReloadMonthly(false)
        }

        if (reloadYearly && !isDatesCountLoading && availableDates.length > 0 && selectedDate !== '') {
            dispatch(getYearlyDataList(token, factoryId, selectedDate))
            setReloadYearly(false)
        }

    }, [
        selectedDate, 
        reloadDaily, 
        reloadMonthly, 
        reloadYearly, 
        dispatch, 
        factoryId, 
        token,
        availableDates.length,
        isDatesCountLoading,
    ])

    useEffect(() => {

        if (isConnected) {
            const schema = factoryDetails.schema
            var topic = "sensors/" + schema + "/all"
            mqttSubscribe(topic)
        }

    }, [isConnected, factoryDetails.schema, mqttSubscribe])    

    useEffect(() => {
        
        if (payload.message) {        

            var obj = JSON.parse(payload.message.toString())
            var tmp = []
            var lt = []

            for (var i = 0; i < obj.length; i++) {
                
                for (var j = 0; j < sensors.length; j++) {
                    
                    if (obj[i].name === sensors[j].name) {

                        tmp.splice(j, 0, obj[i].calculated)
                        lt.splice(j, 0, obj[i].logtime)

                    }

                }

            }

            setReadings(tmp)                
            setLogtime(lt)
            setIsLoading(false)
            setIsMqttConnected(true)

        }

    }, [payload, sensors])

    useEffect(() => {
        window.scrollTo(0, 0)
        mqttConnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const handleChange = (event, newValue) => {

        setValue(newValue)

        if (newValue === 0) {

            setShowDateChooser(false)
            setShowMonthChooser(false)
            setShowYearChooser(false)
            setShowNotification(true)

        } else if (newValue === 1) {

            setShowDateChooser(true)
            setShowMonthChooser(false)
            setShowYearChooser(false)
            setShowNotification(false)

        } else if (newValue === 2) {

            setShowDateChooser(false)
            setShowMonthChooser(true)
            setShowYearChooser(false)
            setShowNotification(false)

        } else if (newValue === 3) {

            setShowDateChooser(false)
            setShowMonthChooser(false)
            setShowYearChooser(true)
            setShowNotification(false)

        }

    }

    const handleSensorChange = (event, newValue) => setSensorDisplayValue(newValue)

    const handleDateChange = date => {
        
        setSelectedDate(date.format("YYYY-MM-DD"))
        setReloadDaily(true)
        setReloadMonthly(true)
        setReloadYearly(true)

    }

    const disableDates = date => {
        var needle = date.format("YYYY-MM-DD");
        return (availableDates.indexOf(needle) > -1) ? false : true;
    }

    const currentReading = () => (
        <Grid container>
            {isLoading === false ? sensors.map((key, i) => (
            <Grid key={i} item xs={12} md={6} lg={3} sx={{paddingTop:1,paddingRight:1,paddingBottom:1,paddingLeft:0}}>
                <Paper sx={{padding:1}}>
                    <Subtitle>{sensors[i].name}</Subtitle>
                    {isMqttConnected === false ? 
                    <Box sx={{margin:3,display:'flex',alignItems:'center',height:50,justifyContent:'center'}}>
                        <CircularProgress size={30} />
                    </Box>
                    : 
                    <>
                    <Typography 
                        color={readings[i] > sensors[i].max_limit ? "orangered" : "forestgreen"} 
                        component="p" 
                        variant="h4">
                        {readings[i]} {sensors[i].unit}
                    </Typography>
                    <Typography color="textSecondary" component="span" variant="body2">
                        {logtime[i]}
                    </Typography>
                    </>
                    }                    
                    
                </Paper>
            </Grid>
            )) :
            <React.Fragment>
            <Grid item xs={12} md={6} lg={6}>
                <Skeleton variant="rounded" height={100} sx={{marginBottom:1}} />
            </Grid> 
            <Grid item xs={12} md={6} lg={6}>
                <Skeleton variant="rounded" height={100} />
            </Grid> 
            </React.Fragment> 
            }
        </Grid>
    )

    const dailySensorsCharts = () => (
        <Grid item xs={12} md={12} lg={12}>
            {sensors.map((key, i) => (
                <Grid key={i} item xs={12} md={12} lg={12}>                                        
                    <MobileView>
                        <Typography variant="h5" component="h5">{sensors[i].name}</Typography>
                        <Paper sx={{marginBottom:4,marginTop:1}}>
                            <ReadingsChart 
                                sensors={sensors} 
                                data={dailyData} 
                                i={i} 
                                format='HH:mm' />
                        </Paper>
                    </MobileView>
                    <BrowserView>
                        <Typography variant="h5" component="h5" sx={{marginBottom:1}}>{sensors[i].name}
                            <CSVLink 
                                data={csvData[i]}
                                filename={factoryDetails.name + " - " + sensors[i].name + ", " + selectedDate + ".csv"}>
                                <Button variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{marginLeft:2}}>Export as csv</Button>
                            </CSVLink>
                        </Typography>
                        <Paper sx={{marginBottom:5,marginTop:0,paddingTop:5}}>
                            <CanvasJSChart style={{padding:50}} options={
                                {
                                    zoomEnabled: true,
                                    animationEnabled: true,
                                    toolTip: {
                                        borderThickness: 1,
                                        borderColor: "#CCCCCC",
                                        fontColor: "#666666"
                                    },
                                    axisX: { 
                                        margin:40,
                                        lineColor: "#CCCCCC",
                                        lineThickness: 2,
                                        labelFontColor: "#808080",
                                        intervalType: "minute",
                                        valueFormatString: "HH:mm",
                                        stripLines: hourlyCcData[i]
                                    },
                                    axisY: {
                                        gridColor: "#DDDDDD",
                                        lineColor: "#CCCCCC",
                                        labelFontColor: "#808080",
                                        lineThickness: 2,
                                        gridDashType: "dash",
                                        margin:40,
                                        title: "",
                                        stripLines: [{
                                            value: sensors[i].max_limit,
                                            lineDashType: "dash"
                                        }]
                                    },
                                    data: [{
                                        type: "line",
                                        lineColor: LINECHART_COLORS[i],
                                        xValueType: "dateTime",
                                        xValueFormatString:"hh:mm TT",
                                        percentFormatString: "#0.##",
                                        toolTipContent: "{x} - {y} " + sensors[i].unit,
                                        dataPoints: hourlyData[i]
                                    }]
                                }} /* onRef = {ref => this.chart = ref} */ />
                            <ControlChartsDesktopTableList cc={hourlyCcData[i]} sensor={sensors[i]} />
                        </Paper>
                    </BrowserView>
                </Grid>
            ))}
        </Grid>
    )

    const monthlySensorsCharts = () => (
        <Grid item xs={12} md={12} lg={12}>
            {sensors.map((key, i) => (
                <Grid key={i} item xs={12} md={12} lg={12}>
                    <Typography variant="h5" component="h5">{sensors[i].name}</Typography>
                    <Paper sx={{marginBottom:4,marginTop:1}}>
                        <ReadingsChart 
                            sensors={sensors} 
                            data={monthlyData} 
                            i={i}
                            format='D' />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    )

    const yearlySensorsCharts = () => (
        <Grid item xs={12} md={12} lg={12}>
            {sensors.map((key, i) => (
                <Grid key={i} item xs={12} md={12} lg={12}>
                    <Typography variant="h5" component="h5">{sensors[i].name}</Typography>
                    <Paper sx={{marginBottom:4,marginTop:1}}>
                        <ReadingsChart 
                            sensors={sensors} 
                            data={yearlyData} 
                            i={i}
                            format='MMM' />                   
                    </Paper>
                </Grid>
            ))}
        </Grid>
    )

    const showDesktopDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <BrowserView>
                    {isLoading ?
                    <Skeleton variant="rounded" height={200} /> :
                    <SensorDesktopTableList sensors={sensors} />
                    }                
                </BrowserView>
            </Grid>
        </Grid>
    )

    const showMobileDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <MobileView>                
                    {isLoading ?
                    <Skeleton variant="rounded" height={200} /> :
                    <SensorMobileTableList 
                        sensors={sensors}                        
                        sensorDisplayValue={sensorDisplayValue}
                        handleSensorChange={handleSensorChange} />
                    }
                </MobileView>
            </Grid>
        </Grid>
    )

    const a11yProps = (index) => {
        return {
            id  : `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        }
    }

    return(
        <>

            <LeftDrawer handleDrawerClose={handleDrawerClose} open={open} />

            <>
                {isLoading || isLoadingHourlyData || isLoadingDailyData || isLoadingMonthlyData || isLoadingYearlyData ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    token={token}
                    factoryId={factoryId}
                    showDateChooser={showDateChooser}
                    showMonthChooser={showMonthChooser}
                    showYearChooser={showYearChooser}
                    showNotification={showNotification}
                    availableDates={availableDates}
                    handleDateChange={handleDateChange}
                    disableDates={disableDates}
                    selectedDate={selectedDate}
                    updateReadNotifications={false}
                    unreadCount={notificationsUnreadCount}
                    notifications={notifications}
                    showSearch={false}
                    handleDrawerOpen={handleDrawerOpen} />
                        
                <Container maxWidth={false} style={{marginTop:80}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Title>{factoryDetails.name}</Title>
                        <Subtitle>{factoryDetails.company}</Subtitle>
                    </Grid>         
                    <>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            style={{marginTop:40}}
                            value={value}
                            onChange={handleChange}
                            aria-label="scrollable auto tabs">
                            <Tab label="Readings" {...a11yProps(0)} />
                            <Tab label="Daily" {...a11yProps(1)} />
                            <Tab label="Monthly" {...a11yProps(2)} />
                            <Tab label="Yearly" {...a11yProps(3)} />
                        </Tabs>            
                        <Divider style={{marginBottom:20}} />
                        <TabPanel value={value} index={0}>
                            {currentReading()}
                            <Typography sx={{marginTop:4,marginBottom:2}} variant="h5" component="h5">
                                List of all sensor
                            </Typography>
                            {showDesktopDataTable()}
                            {showMobileDataTable()}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {dailySensorsCharts()}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {monthlySensorsCharts()}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {yearlySensorsCharts()}
                        </TabPanel>            
                    </>
                </Container>

                <Footer />

            </>

        </>
    )

}
