import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const dailyDataSlice = createSlice({
    name: "dailyData",
    initialState: {
        isLoading: false,
        dailyData: []
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setDailyData: (state, action) => {
            state.dailyData = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setDailyData
} = dailyDataSlice.actions

export const getDailyDataList = (
    token, 
    factoryId, 
    selectedDate
) => async (dispatch) => {

    dispatch(startLoading())

    const splitUpDate = selectedDate.split("-")
    const year = splitUpDate[0]
    const month = splitUpDate[1]
    const day = splitUpDate[2]

    var getDailyData =
        apiGateway.get_daily_readings(
            token, 
            factoryId, 
            year, 
            month, 
            day
        )

    Promise.all([getDailyData])
        .then((values) => {
            
            dispatch(setDailyData(values[0]))
            dispatch(stopLoading())

        })

}

export const selectDailyDataList = (state) => state.dailyData.dailyData
export const selectDailyDataLoading = (state) => state.dailyData.isLoading

export default dailyDataSlice.reducer