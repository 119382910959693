import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import NotificationsIcon from '@mui/icons-material/Notifications'
import WarningIcon from '@mui/icons-material/Warning'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { setReadNotifications } from '../redux/features/notification/notificationSlice'
import { Popover, Box, IconButton, Typography, Badge, List, ListItem, ListItemText, ListItemAvatar, Divider } from '@mui/material'

export default function NotificationsPopover(props) {

    const dispatch = useDispatch()    
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        dispatch(setReadNotifications(props.token, props.factoryId))
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <div>
            <IconButton
                onClick={handleClick}
                size="large"
                aria-label="show new notifications"
                color="inherit">
                <Badge badgeContent={props.unreadCount} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>                
                {props.notifications !== undefined && Object.entries(props.notifications).length !== 0 ? 
                <Box sx={{padding:0}}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {props.notifications.map((notification, key) => (
                        <React.Fragment key={key}>                            
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {notification.severity === "info" && notification.has_read === true ? <InfoOutlinedIcon fontSize="medium" color={notification.severity} /> : null}
                                    {notification.severity === "info" && notification.has_read === false ? <Badge color="secondary" variant="dot"><InfoOutlinedIcon fontSize="medium" color={notification.severity} /></Badge> : null}
                                    {notification.severity === "warning" && notification.has_read === true ? <WarningIcon fontSize="medium" color={notification.severity} /> : null}
                                    {notification.severity === "warning" && notification.has_read === false ? <Badge color="secondary" variant="dot"><WarningIcon fontSize="medium" color={notification.severity} /></Badge> : null}
                                    {notification.severity === "error"  && notification.has_read === true ? <ErrorOutlineOutlinedIcon fontSize="medium" color={notification.severity} /> : null}
                                    {notification.severity === "error"  && notification.has_read === false ? <Badge color="secondary" variant="dot"><ErrorOutlineOutlinedIcon fontSize="medium" color={notification.severity} /></Badge> : null}
                                    {notification.severity === "success" && notification.has_read === true ? <CheckCircleOutlineOutlinedIcon fontSize="medium" color={notification.severity} /> : null}
                                    {notification.severity === "success" && notification.has_read === false ? <Badge color="secondary" variant="dot"><CheckCircleOutlineOutlinedIcon fontSize="medium" color={notification.severity} /></Badge> : null}
                                </ListItemAvatar>

                                <ListItemText
                                    primary={notification.title}
                                    secondary={
                                        <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary">
                                            {notification.logtime}
                                        </Typography> — {notification.description}
                                        </React.Fragment>
                                    }
                                />                                
                            </ListItem>                            
                            {key !== Object.entries(props.notifications).length-1 ? <Divider variant="inset" component="li" /> : null}
                        </React.Fragment>
                        )) }
                    </List>
                </Box>
                : null }                
            </Popover>
        </div>
    )
}
