import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'

const footers = [
    {
        title: 'About',
        description: ['Privacy policy', 'Terms and conditions', 'Frequently Asked Questions'],
        links : ["/privacy-policy", "/terms-and-conditions", "/faq"]
    },
    {
        title: 'Features',
        description: ['Data acquisition', 'Notifications and alerts', 'DoE compliant'],
        links : ["#", "#", "#"]
    },
    {
        title: 'Support',
        description: ['Monday to Friday', '9:00am to 5:00pm', 'help@deriabiru.my'],
        links : ["#", "#", "#"]
    }
]

export default function Footer() {

    const copyright = () => (
        <Grid container justifyContent={"space-evenly"} sx={{marginTop:4}}>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'© '}Deria Biru Sdn Bhd{' 2014 - '}{new Date().getFullYear()}
                </Typography>
            </Grid>
        </Grid>
    )

    const menu = () => (
        <Grid container justify="space-evenly">
            {footers.map((footer) => (
            <Grid item xs={12} sm={4} md={4} lg={4} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
                </Typography>
                <ul>
                    {footer.description.map((item, i) => (
                    <li key={item}>
                    {footer.links[i] !== "#" ?
                    <Link to={footer.links[i]}>
                    {item}
                    </Link>
                    : null}
                    {footer.links[i] === "#" ? 
                    <Typography variant="body2">{item}</Typography> : null }
                    </li>
                    ))}
                </ul>
            </Grid>
            ))}
        </Grid>
    )

    return(
        <Container component="footer" sx={{padding:2,marginTop:10}}>
            {menu()}
            {copyright()}
        </Container>
    )

}

