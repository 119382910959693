import { 
    EMAIL_MINIMUM_LENGTH,
    PASSWORD_MINIMUM_LENGTH
} from './Constants'

export const isEmailValid = (email) => {

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)

}

export const  isEmailTooShort = (email) => {
    return email.length <= EMAIL_MINIMUM_LENGTH
}

export const isPasswordTooShort = (password) => {
    return password.length < PASSWORD_MINIMUM_LENGTH
}

export const stableSort = (array, comparator) => {

    const stabilizedThis = array.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    return stabilizedThis.map((el) => el[0])

}

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export const descendingComparator = (a, b, orderBy) => {

    if (b[orderBy] < a[orderBy]) {
        return -1
    }

    if (b[orderBy] > a[orderBy]) {
        return 1
    }

    return 0
}


