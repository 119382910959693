import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import HideOnScroll from './HideOnScroll'
import { Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import NotificationsPopover from './NotificationsPopover'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TextField from '@mui/material/TextField'
import * as moment from "moment"

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
}))
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
}))

export default function TopAppBar(props) {
    
    return(
        <HideOnScroll>
            <AppBar position="fixed">
                <Toolbar>

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>

                    <Box style={{flexGrow:1}} />

                    {props.showSearch ?
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            onChange={(e) => props.requestSearch(e.target.value)}
                            value={props.searchedKeyword ? props.searchedKeyword : ""}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }} />
                    </Search>
                    : null }
                    {props.showDateChooser ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker                                 
                            views={['day']}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                            value={props.selectedDate}
                            showToolbar={false}
                            onChange={props.handleDateChange}
                            shouldDisableDate={props.disableDates}
                            closeOnSelect={true}
                            renderInput={(params) => 
                                <TextField                                     
                                    color="fubar" 
                                    sx={{
                                        input:{color:"white"},
                                        svg:{color:"white"},
                                        label:{color:"white"},
                                        '& label.Mui-focused': {
                                            color: '#ffffff7a',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#ffffff7a',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#ffffff4d',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                        },
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    {...params} 
                                    variant="outlined" 
                                    helperText={null} 
                                    size="small" />} 
                            />
                    </LocalizationProvider>                    
                    : null }
                    {props.showMonthChooser === true ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            views={['month']}
                            disableFuture
                            disableMaskedInput
                            inputFormat="MMM, YYYY"                    
                            value={props.selectedDate}
                            onChange={props.handleDateChange}
                            shouldDisableDate={props.disableDates}
                            closeOnSelect={true}
                            minDate={moment(props.availableDates[0]).format("YYYY-MM-DD")}
                            maxDate={moment(props.availableDates[props.availableDates.length-1]).format("YYYY-MM-DD")}
                            renderInput={(params) => 
                                <TextField 
                                    color="fubar" 
                                    sx={{
                                        input:{color:"white"},
                                        svg:{color:"white"},
                                        label:{color:"white"},
                                        '& label.Mui-focused': {
                                            color: '#ffffff7a',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#ffffff7a',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#ffffff4d',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                        },
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    {...params} 
                                    helperText={null} 
                                    size="small" />
                            } 
                        />
                    </LocalizationProvider>
                    : null }
                    {props.showYearChooser === true ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            views={['year']}
                            disableFuture
                            inputFormat="YYYY"                    
                            value={props.selectedDate}
                            onChange={props.handleDateChange}
                            shouldDisableDate={props.disableDates}
                            closeOnSelect={true}
                            minDate={moment(props.availableDates[0]).format("YYYY-MM-DD")}
                            maxDate={moment(props.availableDates[props.availableDates.length-1]).format("YYYY-MM-DD")}
                            renderInput={(params) => 
                                <TextField 
                                    color="fubar" 
                                    sx={{
                                        input:{color:"white"},
                                        svg:{color:"white"},
                                        label:{color:"white"},
                                        '& label.Mui-focused': {
                                            color: '#ffffff7a',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#ffffff7a',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#ffffff4d',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#ffffff7a',
                                            },
                                        },
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    {...params} 
                                    helperText={null}
                                    size="small" />
                            } 
                        />
                    </LocalizationProvider>
                    : null }
                    {props.showNotification ?
                    <NotificationsPopover 
                        token={props.token}
                        factoryId={props.factoryId}
                        updateReadNotifications={props.updateReadNotifications}
                        notifications={props.notifications} 
                        unreadCount={props.unreadCount} />
                    : null }
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    )

}
