import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from "moment"
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import ReadingsChart from '../../ui/ReadingsChart'
import TopAppBar from '../../ui/TopAppBar'
import Title from '../../ui/Title'
import { Container, Grid, Typography, LinearProgress } from '@mui/material'
import { selectSensorList } from '../../redux/features/sensor/sensorSlice'
import { getDatesCountList, selectDatesCountList } from '../../redux/features/datesCount/datesCountSlice'
import { selectToken, selectAuthFactoryId } from '../../redux/features/security/authenticationSlice'
import { getDailyDataList, selectDailyDataList, selectDailyDataLoading } from '../../redux/features/daily/dailyDataSlice'

export default function Daily() {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [hasDatesCountLoaded, setHasDatesCountLoaded] = useState(false)
    const [hasDailyDataLoaded, setHasDailyDataLoaded] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))

    const token = useSelector(selectToken)
    const authFactoryId = useSelector(selectAuthFactoryId)
    const availableDates = useSelector(selectDatesCountList)
    const sensors = useSelector(selectSensorList)
    const dailyData = useSelector(selectDailyDataList)
    const isLoading = useSelector(selectDailyDataLoading)

    useEffect(() => {

        if (!hasDatesCountLoaded) {

            dispatch(getDatesCountList(token, authFactoryId))            
            setHasDatesCountLoaded(true)

        }

        if (!hasDailyDataLoaded && availableDates.length > 0) {

            var d = availableDates[availableDates.length - 1]
            setSelectedDate(d)
            dispatch(getDailyDataList(token, authFactoryId, d))
            setHasDailyDataLoaded(true)

        }

    }, [
        dispatch,
        hasDatesCountLoaded,
        hasDailyDataLoaded,
        availableDates,
        authFactoryId,
        selectedDate,
        token
    ])

    const handleDateChange = date => {
        
        let selected_date = date.format("YYYY-MM-DD")
        setSelectedDate(selected_date)     
        dispatch(getDailyDataList(token, authFactoryId, selected_date))   
        
    }

    const disableDates = date => {
        var needle = date.format("YYYY-MM-DD")
        return (availableDates.indexOf(needle) > -1) ? false : true
    }

    const sensorsCharts = () => (
        <Grid item xs={12} md={12} lg={12} sx={{marginTop:4}}>
            {sensors.map((key, i) => (
                <Grid key={i} item xs={12} md={12} lg={12} sx={{padding:1}}>
                    <ReadingsChart 
                        sensors={sensors} 
                        data={dailyData} 
                        i={i} 
                        format='HH:mm' />
                </Grid>
            ))}
        </Grid>
    )

    const subtitle = () => (
        <Grid item xs={12} md={10} lg={10}>
            <Title>Daily readings</Title>            
            <Typography variant="body2" component="span">
                Showing hourly readings based on selected date.
                Please change the date accordingly.
            </Typography>
        </Grid>
    )

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    return(
        <React.Fragment>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <div>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    disableDates={disableDates}
                    showDateChooser={true}
                    showNotification={false}
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="lg" style={{marginTop:80}}>

                    <Grid container>
                        {subtitle()}
                        {sensorsCharts()}
                    </Grid>

                </Container>
                <Footer />

            </div>

        </React.Fragment>
    )

}