import { useEffect, useState } from 'react'
import Footer from '../../ui/Footer'
import FrontTopAppBar from '../../ui/FrontTopAppBar'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useLocation, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isPasswordTooShort } from '../../utils/Helper'
import { ALERT_WARNING, PASSWORD_NEW_TITLE, PASSWORD_NEW_MSG } from '../../utils/Constants'
import { Grid, Button, LinearProgress, Alert, AlertTitle, Box, FormControl, FormHelperText, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { selectOtpError, forgotPasswordSubmit, selectResetCompleted, selectIsLoading, selectPasswordError, selectPasswordErrorMsg, setPasswordError, setPasswordErrorMsg, newPasswordRequiredSubmit } from '../../redux/features/security/authenticationSlice'

export default function Reset() {

    const dispatch = useDispatch()
    const params = useLocation()

    const [otp, setOtp] = useState("")
    const [newPasswordRequired, setNewPasswordRequired] = useState(false)
    const [email, setEmail] = useState("")
    const [oldPassword, setOldPassword] = useState("")

    const resetCompleted = useSelector(selectResetCompleted)
    const isLoading = useSelector(selectIsLoading)
    const passwordError = useSelector(selectPasswordError)
    const passwordErrorMsg = useSelector(selectPasswordErrorMsg)
    const otpError = useSelector(selectOtpError)

    const [buttonDisable, setButtonDisable] = useState(true)
    const [password, setPassword] = useState("")
    const [passwordValidated, setPasswordValidated] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        // console.log("XXX", params.state)
        setEmail(params.state.email)
        setNewPasswordRequired(params.state.newPassword)
        setOldPassword(params.state.oldPassword)
        setOtp(params.state.pin)
        
        if (submitted) {

            if (newPasswordRequired) {
                dispatch(newPasswordRequiredSubmit(email, oldPassword, password))
            } else {
                dispatch(forgotPasswordSubmit(email, otp, password))    
            }
            
            setSubmitted(false)

        }

    }, [
        submitted, 
        dispatch, 
        passwordError, 
        passwordErrorMsg, 
        newPasswordRequired,
        email, 
        otp, 
        password, 
        params.state.email, 
        params.state.newPassword,
        params.state.oldPassword,
        oldPassword,
        params.state.pin,
        otpError
    ])

    const onSubmit = () => {

        setButtonDisable(true)
        setSubmitted(true)

    }

    const handlePasswordChange = passwordInput => {
        
        if (isPasswordTooShort(passwordInput)) {

            setPasswordValidated(false)
            dispatch(setPasswordError(true))
            dispatch(setPasswordErrorMsg("Too short"))

        } else {

            setPasswordValidated(true)
            dispatch(setPasswordError(false))
            dispatch(setPasswordErrorMsg("Valid password"))

        }

        setPassword(passwordInput)
        validate()

    }

    const validate = () => {

        if (passwordValidated) {
            setButtonDisable(false)
        } else {
            setButtonDisable(true)
        }

    }

    const alert = () => (
        <Alert variant="outlined" severity={ALERT_WARNING}>
            <AlertTitle><strong>{PASSWORD_NEW_TITLE}</strong></AlertTitle>
            {PASSWORD_NEW_MSG}
        </Alert>
    )
    
    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword)

    if (resetCompleted) {

        return <Navigate to={"/"} state={{reset:true, email:email}} />

    } else if (otpError) {

        return <Navigate to={"/otp"} state={{email:email}} />

    } else {

        return (
            <>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}
                <FrontTopAppBar />
                <Grid container sx={{marginTop:8,marginBottom:10}}>                
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} elevation={6} sx={{padding:2}}>
                        {alert()}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>                
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} elevation={6} sx={{padding:2}}>
                        <Box sx={{paddingBottom:4}}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="password" error={passwordError}>Password</InputLabel>
                                <OutlinedInput
                                    variant="outlined"
                                    required
                                    label="Password"
                                    fullWidth
                                    error={passwordError}
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    autoComplete="current-password"
                                    onChange={e => handlePasswordChange(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleTogglePassword}
                                        onMouseDown={handleTogglePassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText
                                    id="outlined-password-helper-text"
                                    error={passwordError}>
                                    {passwordErrorMsg}
                                </FormHelperText>
                            </FormControl>
                            <Button
                                onClick={onSubmit}
                                sx={{marginTop:4}}
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={buttonDisable}>
                                Update Password
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6}></Grid>
                </Grid>
                <Footer />
            </>
        )
    }

}
