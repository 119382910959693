import { API_GATEWAY_ENDPOINT } from "../utils/Constants"
import * as moment from "moment"

export const apiGateway = {
    get_schema,
    get_sensors,
    get_dates_count,
    get_hourly_readings,
    get_daily_readings,
    get_weekly_readings,
    get_monthly_readings,
    get_yearly_readings,
    get_all_sensor,
    get_all_company,
    get_all_factory,
    get_all_distributor,
    get_all_customer,
    get_notifications,
    get_notifications_by_factory,
    set_read_notifications
}

const options = {
    crossDomain : true,
    headers     : { 'Content-Type' : 'application/json' }
}

const postOptions = {
    method  : 'POST',
    headers : { 'Content-Type' : 'application/json' }
}

async function get_schema(token) {
    
    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/schema"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (ret) => {

                    resolve({
                        schema              : ret["schema"],
                        factory_name        : ret["factory"],
                        company_name        : ret["company"],
                        distributor_name    : ret["distributor"]
                    })

                },
                (error) => {
                    reject(error)
                }
            )

    })

    return promise

}

async function get_notifications(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/notification"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve({
                        notifications : result.notifications,
                        unread_count  : result.unread_count
                    })
                }
            )

    })

    return promise

}

async function get_notifications_by_factory(token, factory_id) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/notification/" + factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve({
                        notifications : result.notifications,
                        unread_count  : result.unread_count
                    })
                }
            )

    })

    return promise

}

async function set_read_notifications(token, factory_id) {

    postOptions.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/notification/read/" + factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, postOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve({
                        result: result
                    })
                }
            )

    })

    return promise

}

async function get_sensors(token, factory_id) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/sensor/" + factory_id        

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.sensors)
                },
                (error) => {
                    reject(error)
                }
            )

    })

    return promise

}

async function get_dates_count(token, factory_id) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/data/dates/" + factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    var index       = result.available.length - 1
                    var latest_date = result.available[index]

                    // Set to last available date.
                    var tmp    = latest_date.split("-")
                    var year   = tmp[0]
                    var month  = tmp[1]
                    var day    = tmp[2]

                    resolve({
                        selectedDate    : latest_date,
                        availableDates  : result.available,
                        year            : year,
                        month           : month,
                        day             : day
                    })

                },
                (error) => {
                    reject(error)
                }
            )

    })

    return promise

}

async function get_hourly_readings(token, factory_id, year, month, day) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/data/hourly/" +
            year + "/" +
            month + "/" +
            day + "/" + 
            factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    var csv = []
                    var data = []
                    var cc = []
                    var sensors_data = result["data"]
                    var sensors_cc = result["cc"]

                    for (var sensor_name in sensors_data) {

                        var data_points  = []
                        var csv_points = []

                        for (var idx in sensors_data[sensor_name]) {

                            var row = sensors_data[sensor_name][idx];
                            var point = {x: row["logtime"] * 1000, y: row["average"]}
                            data_points.push(point)

                            var csv_line = {datetime: moment.unix(row["logtime"]).format('DD-MM-YYYY HH:mm'), average: row["average"]}
                            csv_points.push(csv_line)

                        }

                        data.push(data_points)
                        csv.push(csv_points)

                    }

                    for (sensor_name in sensors_cc) {

                        var cc_points = []

                        for (idx in sensors_cc[sensor_name]) {

                            row = sensors_cc[sensor_name][idx];
                            point = {
                                id          : row["id"],
                                startValue  : (row["logtime"]-300)*1000, 
                                endValue    : (row["logtime"]+300)*1000,
                                color       : 'rgba(211, 211, 211, 0.25)',
                                logtime     : row["logtime"],
                                avg_data    : row["avg_data"]
                            }
                            cc_points.push(point)

                        }

                        cc.push(cc_points)

                    }

                    resolve([data, cc, csv])
                },
            )
    })

    return promise

}

async function get_daily_readings(token, factory_id, year, month, day) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/data/daily/" +
            year + "/" +
            month + "/" +
            day + "/" + 
            factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    var data = []
                    var sensors = result["data"]

                    for (var sensor_name in sensors) {

                        var tmp  = []

                        for (var idx in sensors[sensor_name]) {

                            var row = sensors[sensor_name][idx];

                            var point = {}
                            point[sensor_name] = row["average"]
                            point["logtime"] = row["logtime"]
                            point["name"] = sensor_name
                            tmp.push(point)

                        }

                        data.push(tmp)
                    }

                    resolve(data)

                },
                // (error) => {
                //     console.error(error)
                // }
            )

    })

    return promise

}

async function get_monthly_readings(token, factory_id, year, month, day) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/data/monthly/" +
            year + "/" +
            month + "/" +
            day + "/" + 
            factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    var data = []
                    var sensors = result["data"]

                    for (var sensor_name in sensors) {

                        var tmp  = []

                        for (var idx in sensors[sensor_name]) {

                            var row = sensors[sensor_name][idx];

                            var point = {}
                            point[sensor_name] = row["average"]
                            point["logtime"] = row["logtime"]
                            point["name"] = sensor_name
                            tmp.push(point)

                        }

                        data.push(tmp)
                    }

                    resolve(data)

                },
                // (error) => {
                //     console.error(error)
                // }
            )

    })

    return promise

}

async function get_yearly_readings(token, factory_id, year, month, day) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/data/yearly/" +
            year + "/" +
            month + "/" +
            day + "/" + 
            factory_id

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {

                    var data = []
                    var sensors = result["data"]

                    for (var sensor_name in sensors) {

                        var tmp  = []

                        for (var idx in sensors[sensor_name]) {

                            var row = sensors[sensor_name][idx];

                            var point = {}
                            point[sensor_name] = row["average"]
                            point["logtime"] = row["logtime"]
                            point["name"] = sensor_name
                            tmp.push(point)

                        }

                        data.push(tmp)
                    }

                    resolve(data)

                },
                (error) => {
                    reject(error)
                }
            )

    })

    return promise

}

async function get_weekly_readings(token, factory_id, sensor_id, year, month, day) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT +
        "/data/weekly/" +
        factory_id + "/" +
        sensor_id + "/" +
        year + "/" +
        month + "/" +
        day

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}

async function get_all_sensor(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/sensor/all"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}

async function get_all_company(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/company/all"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}

async function get_all_factory(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/factory/all"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}

async function get_all_distributor(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/distributor/all"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}

async function get_all_customer(token) {

    options.headers["Authorization"] = token

    var url = API_GATEWAY_ENDPOINT + "/customer/all"

    const promise = new Promise(function(resolve, reject) {

        fetch(url, options)
            .then(res => res.json())
            .then(
                (result) => {
                    resolve(result.data)
                }
            )

    })

    return promise

}
