import React from 'react'
//import theme from './Theme.js'
import Slide from '@mui/material/Slide'
import { useScrollTrigger } from '@mui/material'

const HideOnScroll = (props) => {

    const {children, window} = props
    const trigger = useScrollTrigger({target: window ? window() : undefined})

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
    
}

export default (HideOnScroll)
