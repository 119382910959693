import { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import Footer from '../../ui/Footer'
import HideOnScroll from '../../ui/HideOnScroll'
import DeriaBiruLogo from '../../ui/WhiteDeriaBiruLogo'
import { isEmailValid, isEmailTooShort, isPasswordTooShort } from '../../utils/Helper'
import { Grid, Button, TextField, FormControl, FormHelperText, InputLabel, OutlinedInput, InputAdornment, IconButton, AppBar, Toolbar, Box, LinearProgress } from '@mui/material'
import { EMAIL_EMPTY_ERROR_MSG, EMAIL_TOO_SHORT_ERROR_MSG, EMAIL_INVALID_ERROR_MSG, EMAIL_IS_VALID, PASSWORD_IS_VALID, PASSWORD_TOO_SHORT_ERROR_MSG, PASSWORD_ERROR_MSG } from '../../utils/Constants'
import { checkUserCurrentStatus, selectRole, selectUserLoggedIn, selectIsLoading, loginToCognito, selectPasswordError, selectPasswordErrorMsg, selectResetPassword, selectNewPasswordRequired, setPasswordError, setPasswordErrorMsg, forgotPassword, getToken } from '../../redux/features/security/authenticationSlice'

export default function Login() {
    
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [emailErrorMsg, setEmailErrorMsg] = useState(EMAIL_EMPTY_ERROR_MSG)
    const [emailValidated, setEmailValidated] = useState(false)
    const [passwordValidated, setPasswordValidated] = useState(false)
    const [redirectDashboard, setRedirectDashboard] = useState(false)
    const [redirectAdminDashboard, setRedirectAdminDashboard] = useState(false)
    const [redirectDistributorDashboard, setRedirectDistributorDashboard] = useState(false)
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [forgotButtonDisabled, setForgotButtonDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [forgottenPassword, setForgottenPassword] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [hasCheckedUserCurrentStatus, setHasCheckedUserCurrentStatus] = useState(false)

    const role = useSelector(selectRole)
    const isLoading = useSelector(selectIsLoading)
    const userLoggedIn = useSelector(selectUserLoggedIn)
    const passwordError = useSelector(selectPasswordError)
    const passwordErrorMsg = useSelector(selectPasswordErrorMsg)
    const redirectResetPassword = useSelector(selectResetPassword)
    const redirectNewPasswordRequired = useSelector(selectNewPasswordRequired)

    const dispatch = useDispatch()
    const params = useLocation()

    useEffect(() => {

        /**
         * We might receive email address from the forgot password page
         * or when user's password has been reset. If so, we will set the
         * email address to the email field.
         */
        if (params.state !== null && params.state.email !== null && email === '') {
            setEmail(params.state.email)
            setEmailValidated(true)
        }
        
        /**
         * We will check if the user is logged in or not once the page is loaded.
         * If user is logged in, we will redirect the user to the dashboard page.
         */
        if (!hasCheckedUserCurrentStatus) {

            dispatch(checkUserCurrentStatus())
            setHasCheckedUserCurrentStatus(true)

        }

        if (userLoggedIn) {
            dispatch(getToken())
            redirectUserAccordingly(role)
        }

        /**
         * Handle the login request once the user 
         * click the login button.
         */
        if (submitted) {

            setSubmitButtonDisabled(true)
            setForgotButtonDisabled(true)
            setSubmitted(false)            
            dispatch(loginToCognito(email, password))

        }

        /**
         * Handle the forgotten password request once the user 
         * click the forgot password button.
         */ 
        if (forgottenPassword) {

            setSubmitButtonDisabled(true)
            setForgotButtonDisabled(true)

            if (!emailValidated) {
                    
                setEmailError(true)
                setEmailErrorMsg(EMAIL_EMPTY_ERROR_MSG)
                setSubmitButtonDisabled(false)
                setForgotButtonDisabled(false)

            } else {

                dispatch(forgotPassword(email))

            }

        }

        if (passwordError) {                
            setSubmitButtonDisabled(false)
            setForgotButtonDisabled(false)
        }

    }, [
        submitted,
        forgottenPassword,
        dispatch, 
        email, 
        password, 
        userLoggedIn, 
        hasCheckedUserCurrentStatus,
        emailValidated,
        params.state,
        role,
        passwordError,
        redirectNewPasswordRequired
    ])

    const redirectUserAccordingly = (role) => {

        switch(role) {

            case "4":
                setRedirectDashboard(true)
                break

            case "1":
            case "3":
                setRedirectAdminDashboard(true)
                break
                
            case "2":
                setRedirectDistributorDashboard(true)
                break

            default:
                break

        }

    }

    const handleEmailChange = (value) => {
        
        if (isEmailTooShort(value)) {

            setEmailValidated(false)
            setEmailError(true)
            setEmailErrorMsg(EMAIL_TOO_SHORT_ERROR_MSG)

        } else if (!isEmailValid(value)) {

            setEmailValidated(false)
            setEmailError(true)
            setEmailErrorMsg(EMAIL_INVALID_ERROR_MSG)

        } else {

            setEmailValidated(true)
            setEmailError(false)
            setEmailErrorMsg(EMAIL_IS_VALID)
            
        }

        setEmail(value)

    }

    const handlePasswordChange = (value) => {

        if (isPasswordTooShort(value)) {

            setPasswordValidated(false)
            dispatch(setPasswordError(true))
            dispatch(setPasswordErrorMsg(PASSWORD_TOO_SHORT_ERROR_MSG))

        } else {

            setPasswordValidated(true)
            dispatch(setPasswordError(false))
            dispatch(setPasswordErrorMsg(PASSWORD_IS_VALID))

        }

        setPassword(value)

    }

    const onSubmit = () => {
        
        if (emailValidated && passwordValidated) {
            setSubmitted(true)
        } else {
            
            if (email.length === 0) {

                setEmailError(true)
                setEmailErrorMsg(EMAIL_EMPTY_ERROR_MSG)

            } else if (isEmailTooShort(email)) {

                setEmailError(true)
                setEmailErrorMsg(EMAIL_TOO_SHORT_ERROR_MSG)

            } else if (!isEmailValid(email)) {

                setEmailError(true)
                setEmailErrorMsg(EMAIL_INVALID_ERROR_MSG)

            }

            if (password.length === 0) {

                dispatch(setPasswordError(true))
                dispatch(setPasswordErrorMsg(PASSWORD_ERROR_MSG))

            } else if (isPasswordTooShort(password)) {

                dispatch(setPasswordError(true))
                dispatch(setPasswordErrorMsg(PASSWORD_TOO_SHORT_ERROR_MSG))

            } 

        }

    }

    const onForgot = () => setForgottenPassword(true)
    
    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword)

    if (redirectNewPasswordRequired) {

        return <Navigate to={"/reset"} state={{email:email,newPassword:true,oldPassword:password}} />

    } else if (redirectResetPassword) {

        return <Navigate to={"/otp"} state={{email:email}} />

    } else if (redirectDashboard) {

        return <Navigate to={"/reading"} />

    } else if (redirectDistributorDashboard) {

        return <Navigate to={"/distributor/factory"} />

    } else if (redirectAdminDashboard) {

        return <Navigate to={"/admin/factory"} />

    } else {
    
        return(
            <>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}
                <HideOnScroll>
                    <AppBar position="fixed">
                        <Toolbar variant="dense">
                            <Box style={{flexGrow:1}} />
                            <DeriaBiruLogo />
                            <Box style={{flexGrow:1}} />
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Grid container sx={{marginTop:8, marginBottom:10}}>                    
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6} sx={{padding:2}}></Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} elevation={6} sx={{padding:2}}>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                value={email}
                                onChange={e => handleEmailChange(e.target.value)}
                                required
                                error={emailError}
                                helperText={emailErrorMsg}
                                fullWidth
                                id="email"
                                label="Email address"
                                name="email" 
                                autoComplete="email" />
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="password" error={passwordError}>Password</InputLabel>
                                <OutlinedInput
                                    variant="outlined"
                                    label="Password"
                                    required
                                    fullWidth
                                    error={passwordError}
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    autoComplete="current-password"
                                    onChange={e => handlePasswordChange(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleTogglePassword}
                                        onMouseDown={handleTogglePassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText
                                    id="outlined-password-helper-text"
                                    error={passwordError}>
                                    {passwordErrorMsg}
                                </FormHelperText>
                            </FormControl>
                            <Button
                                sx={{marginTop:6,}}
                                onClick={onSubmit}
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={submitButtonDisabled}>
                                Sign In
                            </Button>
                            <Button
                                sx={{marginTop:2,}}
                                onClick={onForgot}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={forgotButtonDisabled}>
                                Forgot password
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4} elevation={6} sx={{padding:2}}></Grid>
                    </Grid>
                <Footer />
            </>
        )
    }

}