import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authenticationReducer from './features/security/authenticationSlice'
import factorySlice from '../features/factory/factorySlice'
import notificationSlice from './features/notification/notificationSlice'
import sensorSlice from './features/sensor/sensorSlice'
import datesCountSlice from './features/datesCount/datesCountSlice'
import hourlyDataSlice from './features/hourly/hourlyDataSlice'
import dailyDataSlice from './features/daily/dailyDataSlice'
import monthlyDataSlice from './features/monthly/monthlyDataSlice'
import yearlyDataSlice from './features/yearly/yearlyDataSlice'
import profileSlice from './features/profile/profileSlice'
import customerSlice from '../features/customer/customerSlice'
import companySlice from '../features/company/companySlice'
import distributorSlice from '../features/distributor/distributorSlice'

const rootReducer = combineReducers({ 
    authentication  : authenticationReducer,
    factory         : factorySlice,
    notification    : notificationSlice,
    sensor          : sensorSlice,
    datesCount      : datesCountSlice,
    hourlyData      : hourlyDataSlice,
    dailyData       : dailyDataSlice,
    monthlyData     : monthlyDataSlice,
    yearlyData      : yearlyDataSlice,
    profile         : profileSlice,
    customer        : customerSlice,
    company         : companySlice,
    distributor     : distributorSlice
})

export const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    })
}