import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

export default function TabPanel(props) {
    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
            id={`simple-tabpanel-${props.index}`}
            aria-labelledby={`simple-tab-${props.index}`}
            {...props.other}>
            {props.value === props.index && (
                <Box div={3}>
                {props.children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children    : PropTypes.node,
    index       : PropTypes.any.isRequired,
    value       : PropTypes.any.isRequired,
}
