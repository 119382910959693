import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { setupStore } from "./redux/store"
import './index.css'
import App from './App'

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

const app = (
    <Provider store={setupStore()}>        
        <App />
    </Provider>
)

root.render(app)