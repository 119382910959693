/**
 * Login related constants.
 */
export const PASSWORD_MINIMUM_LENGTH = 16
export const EMAIL_MINIMUM_LENGTH = 8

/**
 * Error messages.
 */
export const EMAIL_EMPTY_ERROR_MSG = "Fill in your e-mail address"
export const EMAIL_INVALID_ERROR_MSG = "Fill in a valid e-mail address"
export const EMAIL_TOO_SHORT_ERROR_MSG = "E-mail address is too short"
export const PASSWORD_ERROR_MSG = "Fill in your valid password"
export const PASSWORD_TOO_SHORT_ERROR_MSG = "Minimum password length is 16 characters"
export const PASSWORD_FORGOT_ERROR_MSG = "Forgotten password, trying to reset"

/**
 * Information/instruction messages.
 */
export const EMAIL_IS_VALID = "E-mail address is valid"
export const PASSWORD_IS_VALID = "Password is valid"
export const PASSWORD_RESET_TITLE = "Password has been reset"
export const PASSWORD_RESET_MSG = "Please login with your new password."
export const PASSWORD_EXPIRED_TITLE = "Your password has expired"
export const PASSWORD_EXPIRED_MSG = "Fill the 6 digit OTP code that we sent to your e-mail address in order to reset password"
export const PASSWORD_NEW_TITLE = "Create a new password"
export const PASSWORD_NEW_MSG = "Please create a new password for your account."

/**
 * Alert types.
 */
export const ALERT_SUCCESS = "success"
export const ALERT_WARNING = "warning"
export const ALERT_ERROR = "error"

/**
 * Line chart colors.
 */
export const LINECHART_COLORS = ["#edc240", "#afd8f8", "#cb4b4b"]

/**
 * MQTT stuff
 */
export const MQTTS_HOST = process.env.REACT_APP_MQTT_URI
export const MQTTS_USERNAME = process.env.REACT_APP_MQTT_USERNAME
export const MQTTS_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD
export const MQTTS_PORT = process.env.REACT_APP_MQTT_PORT

/**
 * API Gateway
 */
export const API_GATEWAY_ENDPOINT = process.env.REACT_APP_API_URI