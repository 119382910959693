import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Navigate } from 'react-router'
import { Link } from "react-router-dom"
import DeriaBiruLogo from './DeriaBiruLogo'
import FactoryIcon from '@mui/icons-material/Factory'
import SensorsIcon from '@mui/icons-material/Sensors'
import PersonIcon from '@mui/icons-material/Person'
import BadgeIcon from '@mui/icons-material/Badge'
import BusinessIcon from '@mui/icons-material/Business'
import LogoutIcon from '@mui/icons-material/Logout'
import DateRangeIcon from '@mui/icons-material/DateRange'
import HomeIcon from '@mui/icons-material/Home'
// import DashboardIcon from '@mui/icons-material/Dashboard'
// import HubIcon from '@mui/icons-material/Hub'
import MuiDrawer from '@mui/material/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { clearFactory } from '../features/factory/factorySlice'
import { List, ListItemButton, ListItemText, ListItemIcon, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { logoutFromCognito, selectUserLoggedIn, selectUserLoggedOut, selectRole, setMenuSelectedIndex, selectMenuSelectedIndex, checkUserCurrentStatus } from '../redux/features/security/authenticationSlice'

const drawerWidth = 240

const Drawer = styled(MuiDrawer)( 
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
)

export default function LeftDrawer({handleDrawerClose, open}) {

    const dispatch = useDispatch()

    const [logout, setLogout] = useState(false)
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
    const userLoggedIn = useSelector(selectUserLoggedIn)
    const userLoggedOut = useSelector(selectUserLoggedOut)
    const role = useSelector(selectRole)
    const menuSelectedIndex = useSelector(selectMenuSelectedIndex)    

    useEffect(() => {

        if (!userLoggedIn && userLoggedOut) {            
            setLogout(true)
        } else {
            dispatch(checkUserCurrentStatus())
        }

    }, [logout, userLoggedIn, userLoggedOut, dispatch])

    const handleClose = () => setOpenLogoutDialog(false)

    const handleLogout = () => {
        dispatch(logoutFromCognito())
        dispatch(clearFactory())
    }

    const handleAskBeforeLogout = () => setOpenLogoutDialog(true)

    const handleMenuSelected = (index) => {
        dispatch(setMenuSelectedIndex(index))
    }

    if (logout) {

        return <Navigate to={{pathname: '/'}} />

    } else {

        return (
            <>
                <Dialog
                    open={openLogoutDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Logout from this application?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        You don't have to logout everytime you use Orwell. You will be automatically logged in if we detect your session is still valid.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                        Cancel
                        </Button>
                        <Button onClick={handleLogout} color="primary" autoFocus>
                        Logout
                        </Button>
                    </DialogActions>
                </Dialog>
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerClose}>
                    <DeriaBiruLogo />
                    <List>
                        <div>
                            {role === "4" ?
                            <ListItemButton 
                                component={Link} 
                                to="/reading"
                                selected={menuSelectedIndex === 0}
                                onClick={() => handleMenuSelected(0)}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                            : null }
                        </div>
                    </List>
                    {role === "4" ?
                    <React.Fragment>
                    <Divider />
                    <List>
                        <div>
                            <ListItemButton 
                                component={Link} 
                                to="/data/daily" 
                                selected={menuSelectedIndex === 1}
                                onClick={() => handleMenuSelected(1)}>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Daily" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/data/monthly"
                                selected={menuSelectedIndex === 2}
                                onClick={() => handleMenuSelected(2)}>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Monthly" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/data/yearly"
                                selected={menuSelectedIndex === 3}
                                onClick={() => handleMenuSelected(3)}>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Yearly"/>
                            </ListItemButton>
                        </div>
                    </List>
                    </React.Fragment> :
                    role === "2" ?
                    <React.Fragment>
                    <List>
                        <div>
                            <ListItemButton 
                                component={Link} 
                                to="/distributor/factory"
                                selected={menuSelectedIndex === 0}
                                onClick={() => handleMenuSelected(0)}>
                                <ListItemIcon>
                                    <FactoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Factory" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/distributor/sensor"
                                selected={menuSelectedIndex === 1}
                                onClick={() => handleMenuSelected(1)}>
                                <ListItemIcon>
                                    <SensorsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sensor" />
                            </ListItemButton>
                        </div>
                    </List>
                    </React.Fragment> :
                    role === "1" || role === "3" ?
                    <React.Fragment>
                    <Divider />
                    <List>
                        <div>
                            {/*
                            <ListItemButton 
                                component={Link} 
                                to="/admin/dashboard" 
                                selected={menuSelectedIndex === 0}
                                onClick={() => handleMenuSelected(0)}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                            */}
                            <ListItemButton 
                                component={Link} 
                                to="/admin/factory" 
                                selected={menuSelectedIndex === 0} 
                                onClick={() => handleMenuSelected(0)}>
                                <ListItemIcon>
                                    <FactoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Factory" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/admin/sensor" 
                                selected={menuSelectedIndex === 1} 
                                onClick={() => handleMenuSelected(1)}>
                                <ListItemIcon>
                                    <SensorsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sensor" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/admin/customer" 
                                selected={menuSelectedIndex === 2} 
                                onClick={() => handleMenuSelected(2)}>
                                <ListItemIcon>
                                    <BadgeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Customer" />
                            </ListItemButton>
                            <ListItemButton 
                                component={Link} 
                                to="/admin/company" 
                                selected={menuSelectedIndex === 3} 
                                onClick={() => handleMenuSelected(3)}>
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText primary="Company" />
                            </ListItemButton>
                            {/*<ListItemButton 
                                component={Link} 
                                to="/admin/distributor" 
                                selected={menuSelectedIndex === 4}
                                onClick={() => handleMenuSelected(4)}>
                                <ListItemIcon>
                                    <HubIcon />
                                </ListItemIcon>
                                <ListItemText primary="Distributor" />
                            </ListItemButton>*/}
                        </div>
                    </List>
                    </React.Fragment>
                    : null}
                    <Divider />
                    <List>
                        <div>
                            <ListItemButton 
                                component={Link} 
                                to="/profile"
                                selected={menuSelectedIndex === 6}
                                onClick={() => handleMenuSelected(6)}>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                            <ListItemButton onClick={handleAskBeforeLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign Out" />
                            </ListItemButton>
                        </div>
                    </List>
                </Drawer>
            </>
        )
    
    }

}
