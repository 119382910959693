import React from 'react'
import Skeleton from '@mui/material/Skeleton'

export default function SkeletonRoundedList() {

    return(
        <>
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
            <Skeleton variant="rounded" height={50} sx={{marginBottom:1}} />
        </>
    )

}