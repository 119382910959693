import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const hourlyDataSlice = createSlice({
    name: "hourlyData",
    initialState: {
        isLoading: false,
        csvData: [],
        hourlyData: [],
        hourlyCcData: [],
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setCsvData: (state, action) => {
            state.csvData = action.payload
        },
        setHourlyData: (state, action) => {
            state.hourlyData = action.payload
        },
        setHourlyCcData: (state, action) => {
            state.hourlyCcData = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setCsvData,
    setHourlyData,
    setHourlyCcData
} = hourlyDataSlice.actions

export const getHourlyDataList = (
    token, 
    factoryId, 
    selectedDate
) => async (dispatch) => {

    dispatch(startLoading())

    const splitUpDate = selectedDate.split("-")
    const year = splitUpDate[0]
    const month = splitUpDate[1]
    const day = splitUpDate[2]

    var getHourlyData =
        apiGateway.get_hourly_readings(
            token, 
            factoryId, 
            year, 
            month, 
            day
        )

    Promise.all([getHourlyData])
        .then((values) => {
            
            dispatch(setHourlyData(values[0][0]))
            dispatch(setHourlyCcData(values[0][1]))
            dispatch(setCsvData(values[0][2]))
            dispatch(stopLoading())

        })

}

export const selectHourlyDataList = (state) => state.hourlyData.hourlyData
export const selectHourlyDataLoading = (state) => state.hourlyData.isLoading
export const selectHourlyCcDataList = (state) => state.hourlyData.hourlyCcData
export const selectCsvDataList = (state) => state.hourlyData.csvData

export default hourlyDataSlice.reducer