import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        isLoading: false,
        schema: "",
        factory: "",
        company: "",
        distributor: ""
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setSchema: (state, action) => {
            state.schema = action.payload
        },
        setFactory: (state, action) => {
            state.factory = action.payload
        },
        setCompany: (state, action) => {
            state.company = action.payload
        },
        setDistributor: (state, action) => {
            state.distributor = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setSchema,
    setFactory,
    setCompany,
    setDistributor
} = profileSlice.actions

export const getUserInfo = (token) => async (dispatch) => {

    dispatch(startLoading())

    apiGateway.get_schema(token)
        .then((values) => {

            dispatch(setSchema(values.schema))
            dispatch(setFactory(values.factory_name))
            dispatch(setCompany(values.company_name))
            dispatch(setDistributor(values.distributor_name))
            dispatch(stopLoading())

        })

}

export const selectSchema = (state) => state.profile.schema
export const selectFactory = (state) => state.profile.factory
export const selectCompany = (state) => state.profile.company
export const selectDistributor = (state) => state.profile.distributor
export const selectProfileIsLoading = (state) => state.profile.isLoading

export default profileSlice.reducer