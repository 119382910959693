import * as moment from "moment"
import { LINECHART_COLORS } from '../utils/Constants'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts'

export default function ReadingsChart({sensors, data, i, format}) {

    return(
        <>            
            <ResponsiveContainer width="99%" height={400}>
                <LineChart
                    data={data[i]}
                    margin={{top: 50, right: 25, left: 0, bottom: 25,}}>
                    <XAxis dataKey="logtime"
                        domain = {['auto', 'auto']}
                        scale='time'
                        type='number'
                        tick={{fontSize: 13}}
                        tickFormatter = {(unixTime) => moment
                            .unix(unixTime)
                            .utcOffset('+0800')
                            .format(format)} />
                    <YAxis domain = {['auto', 'auto']} tick={{fontSize: 13}} />
                    <CartesianGrid vertical={false} horizontal={true} strokeDasharray="3 3" />
                    <Tooltip
                        formatter={function(value, name, v) {
                            return `${value} ${sensors[i].unit}`;
                        }}
                        labelFormatter={function(value) {
                            var sensor_name =
                                moment
                                    .unix(value)
                                    .utcOffset('+0800')
                                    .format('Do MMM, h:mm A')

                            return `${sensor_name}`;
                        }} />
                    <Legend />
                    <Line dot={false} type="monotone" dataKey={sensors[i]["name"]} stroke={LINECHART_COLORS[i]} />
                    <ReferenceLine y={sensors[i].max_limit} stroke="red" strokeDasharray="3 3" />
                </LineChart>
            </ResponsiveContainer>
        </>
    )

}