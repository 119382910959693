import { useState, Fragment } from "react"
import * as moment from "moment"
import { visuallyHidden } from '@mui/utils'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { stableSort, getComparator } from '../utils/Helper.js'
import { Chip, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Box, Collapse } from '@mui/material'

function Row({customer}) {

    const [open, setOpen] = useState(false)

    const handleClick = () => {}

    return(
        <Fragment key={customer.id}>
            <TableRow hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                </TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.role}</TableCell>
                <TableCell>{customer.status ? "Active" : "Inactive" }</TableCell>
                <TableCell>{moment.unix(customer.ctime).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 100, paddingRight: 100}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{marginBottom:10}}>
                        {customer.factories.map((factory, index) => {
                            if (factory === null) {
                                return(<Chip key={index} label="All access" variant="outlined" color="success" sx={{marginRight:1,marginBottom:1}} />)
                            } else {
                                return (<Chip key={index} label={factory} variant="outlined" onClick={handleClick} sx={{marginRight:1,marginBottom:1}} />)
                            }
                        })}               
                        <Chip key="666" label={customer.distributor} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )

}

export default function CustomerDesktopTableList({customers}) {
    
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('email')

    const desktopHeadCells = [
        { id: "collapse", numeric: false, disablePadding: false, label: "" },
        { id: "email", numeric: false, disablePadding: false, label: "Email address" },
        { id: "phone", numeric: false, disablePadding: false, label: "Phone number" },
        { id: "role", numeric: false, disablePadding: false, label: "Role" },
        { id: "status", numeric: false, disablePadding: false, label: "Status" },
        { id: "ctime", numeric: false, disablePadding: false, label: "Joined" },
    ]

    const createSortHandler = (property) => (event) => handleRequestSort(event, property)

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)

    }

    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {desktopHeadCells.map((headCell, index) => (
                                <TableCell
                                    key={index}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}>
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(customers, getComparator(order, orderBy))
                        .map((customer, index) => {
                            return(
                                <Row key={index} customer={customer} />
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}