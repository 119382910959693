import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        isLoading: false,
        notifications: [],
        unreadCount: 0,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setNotifications,
    setUnreadCount
} = notificationSlice.actions

export const getNotificationList = (token, factoryId) => async (dispatch) => {

    dispatch(startLoading())

    var getAllNotifcations = 
        apiGateway.get_notifications_by_factory(token, factoryId)

    Promise.all([getAllNotifcations])
        .then((values) => {

            let notifications = values[0]["notifications"]
            let unreadCount = values[0]["unread_count"]
            
            dispatch(setNotifications(notifications))
            dispatch(setUnreadCount(unreadCount))
            dispatch(stopLoading())

        })
}

export const setReadNotifications = (token, factoryId) => async (dispatch) => {

    dispatch(startLoading())

    var setReadNotifcations =
        apiGateway.set_read_notifications(token, factoryId)

    Promise.all([setReadNotifcations])
        .then(() => {
            dispatch(stopLoading())
            dispatch(getNotificationList(token, factoryId))
        })
}

export const selectIsLoading = (state) => state.notification.isLoading
export const selectNotifications = (state) => state.notification.notifications
export const selectUnreadCount = (state) => state.notification.unreadCount

export default notificationSlice.reducer