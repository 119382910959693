import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../api/Orwell'

const customerSlice = createSlice({
    name: "customer",
    initialState: {
        isLoading: false,
        searchedKeyword: "",
        customerList: [],
        copyCustomerList: []
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setCustomers: (state, action) => {
            state.customerList = action.payload
        },
        setCopyCustomers: (state, action) => {
            state.copyCustomerList = action.payload
        },
        setSearchedKeyword: (state, action) => {
            state.searchedKeyword = action.payload
        }        
    }
})

export const {
    startLoading,
    stopLoading,
    setCustomers,
    setCopyCustomers,
    setSearchedKeyword
} = customerSlice.actions

export const getCustomerList = (token) => async (dispatch) => {

    dispatch(startLoading())

    var getCustomers = apiGateway.get_all_customer(token)

    Promise.all([getCustomers])
        .then((values) => {
            
            const customers = values[0]
            dispatch(setCustomers(customers))
            dispatch(setCopyCustomers(customers))
            dispatch(stopLoading())

        })

}

export const selectCustomerIsLoading = (state) => state.customer.isLoading
export const selectCustomerList = (state) => state.customer.customerList
export const selectCopyCustomerList = (state) => state.customer.copyCustomerList
export const selectCustomerSearchedKeyword = (state) => state.customer.searchedKeyword

export default customerSlice.reducer