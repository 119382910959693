import { useState, Fragment } from "react"
import { visuallyHidden } from '@mui/utils'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { stableSort, getComparator } from '../utils/Helper.js'
import { Collapse, Chip, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'

function Row({customer}) {

    const [open, setOpen] = useState(false)
    const handleClick = () => {}

    return(
        <Fragment key={customer.id}>
            <TableRow hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 75, paddingRight: 75}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{marginBottom:10}}>
                        {customer.factories.map((factory, index) => {
                            if (factory === null) {
                                return(<Chip key={index} label="All access" variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />)
                            } else {
                                return (<Chip key={index} label={factory} variant="outlined" onClick={handleClick} sx={{marginRight:1,marginBottom:1}} />)
                            }
                        })}      
                        <Chip label={customer.role} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        {customer.phone !== null ? <Chip label={customer.phone} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} /> : null}                        
                        <Chip label={customer.distributor} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        {customer.status ? <Chip label="Active" variant="outlined" color="success" sx={{marginRight:1,marginBottom:1}} /> : null}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )

}

export default function CustomerMobileTableList({customers}) {

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('email')

    const mobileHeadCells = [
        { id: "collapse", numeric: false, disablePadding: false, label: "" },
        { id: "email", numeric: false, disablePadding: false, label: "Email address" },
    ]

    const createSortHandler = (property) => (event) => handleRequestSort(event, property)

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)

    }

    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key={999999}>
                            {mobileHeadCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}>
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(customers, getComparator(order, orderBy))
                        .map((customer, index) => {
                            return(
                                <Row key={index} customer={customer} />                            
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}