import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const yearlyDataSlice = createSlice({
    name: "yearlyData",
    initialState: {
        isLoading: false,
        yearlyData: []
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setYearlyData: (state, action) => {
            state.yearlyData = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setYearlyData
} = yearlyDataSlice.actions

export const getYearlyDataList = (
    token, 
    factoryId, 
    selectedDate,
) => async (dispatch) => {

    dispatch(startLoading())

    const splitUpDate = selectedDate.split("-")
    const year = splitUpDate[0]
    const month = splitUpDate[1]
    const day = splitUpDate[2]

    var getYearlyData =
        apiGateway.get_yearly_readings(
            token, 
            factoryId, 
            year,
            month,
            day
        )

    Promise.all([getYearlyData])
        .then((values) => {

            dispatch(setYearlyData(values[0]))
            dispatch(stopLoading())

        })

}

export const selectYearlyDataList = (state) => state.yearlyData.yearlyData
export const selectYearlyDataLoading = (state) => state.yearlyData.isLoading

export default yearlyDataSlice.reducer