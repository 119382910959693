import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../api/Orwell'

const factorySlice = createSlice({
    name: "factory",
    initialState: {
        isLoading: false,
        searchedKeyword: "",
        factoryList: [],
        copyFactoryList: [],
        toggleSelectedIndex: "all",
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setFactories: (state, action) => {
            state.factoryList = action.payload
        },
        setCopyFactories: (state, action) => {
            state.copyFactoryList = action.payload
        },
        setSearchedKeyword: (state, action) => {
            state.searchedKeyword = action.payload
        },
        clearFactory: (state) => {
            state.factoryList = []
            state.copyFactoryList = []
        },
        setToggleSelectedIndex: (state, action) => {
            state.toggleSelectedIndex = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setFactories,
    setCopyFactories,
    setSearchedKeyword,
    clearFactory,
    setToggleSelectedIndex
} = factorySlice.actions

export const getFactoryList = (token) => async (dispatch) => {

    dispatch(startLoading())

    var getAllFactory = apiGateway.get_all_factory(token)

    Promise.all([getAllFactory])
        .then((values) => {

            const factories = values[0]
            dispatch(setFactories(factories))
            dispatch(setCopyFactories(factories))
            dispatch(stopLoading())
            
        })
        
}

export const selectIsLoading = (state) => state.factory.isLoading
export const selectFactoryList = (state) => state.factory.factoryList
export const selectCopyFactoryList = (state) => state.factory.copyFactoryList
export const selectSearchedKeyword = (state) => state.factory.searchedKeyword
export const selectToggleSelectedIndex = (state) => state.factory.toggleSelectedIndex

export default factorySlice.reducer