import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../api/Orwell'

const companySlice = createSlice({
    name: "company",
    initialState: {
        isLoading: false,
        searchedKeyword: "",
        companyList: [],
        copyCompanyList: []
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setCompanies: (state, action) => {
            state.companyList = action.payload
        },
        setCopyCompanies: (state, action) => {
            state.copyCompanyList = action.payload
        },
        setSearchedKeyword: (state, action) => {
            state.searchedKeyword = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setCompanies,
    setCopyCompanies,
    setSearchedKeyword
} = companySlice.actions

export const getCompanyList = (token) => async (dispatch) => {

    dispatch(startLoading())

    var getCompanies = apiGateway.get_all_company(token)

    Promise.all([getCompanies])
        .then((values) => {

            const companies = values[0]
            dispatch(setCompanies(companies))
            dispatch(setCopyCompanies(companies))
            dispatch(stopLoading())

        })

}

export const selectCompanyIsLoading = (state) => state.company.isLoading
export const selectCompanyList = (state) => state.company.companyList
export const selectCopyCompanyList = (state) => state.company.copyCompanyList
export const selectCompanySearchedKeyword = (state) => state.company.searchedKeyword

export default companySlice.reducer