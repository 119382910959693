import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import TopAppBar from '../../ui/TopAppBar'
import Title from '../../ui/Title'
import SkeletonRoundedList from '../../ui/SkeletonRoundedList'
import CompanyDesktopTableList from '../../ui/CompanyDesktopTableList'
import CompanyMobileTableList from '../../ui/CompanyMobileTableList'
import { MobileView, isDesktop, isTablet } from 'react-device-detect'
import { selectToken } from '../../redux/features/security/authenticationSlice'
import { Container, Grid, LinearProgress } from '@mui/material'
import { selectCompanySearchedKeyword, setCompanies, getCompanyList, selectCompanyIsLoading, selectCompanyList, selectCopyCompanyList, setSearchedKeyword } from './companySlice'

export default function Company() {

    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const isLoading = useSelector(selectCompanyIsLoading)
    const companies = useSelector(selectCompanyList)
    const copyCompanies = useSelector(selectCopyCompanyList)
    const searchedKeyword = useSelector(selectCompanySearchedKeyword)

    const [open, setOpen] = useState(false)
    const [companiesLoaded, setCompaniesLoaded] = useState(false)

    useEffect(() => {

        if (!companiesLoaded && token !== '') {

            dispatch(getCompanyList(token))
            setCompaniesLoaded(true)

        }

    }, [
        token,
        companiesLoaded,
        dispatch
    ])

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const requestSearch = (searchedVal) => {
        
        const filteredRows = copyCompanies.filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase())
        })

        dispatch(setSearchedKeyword(searchedVal))
        dispatch(setCompanies(filteredRows))

    }

    const subtitle = () => (
        <Grid item xs={12} md={10} lg={10}>
            <Title style={{paddingBottom:10}}>Company</Title>
        </Grid>
    )

    const showDesktopDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                {isDesktop && !isTablet ?
                <div>  
                    {isLoading ? 
                    <SkeletonRoundedList /> : 
                    <CompanyDesktopTableList companies={companies} />
                    }
                </div>
                : null }
            </Grid>
        </Grid>
    )

    const showMobileDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <MobileView>
                    {isLoading ? 
                    <SkeletonRoundedList /> : 
                    <CompanyMobileTableList companies={companies} />
                    }
                </MobileView>
            </Grid>
        </Grid>
    )


    return(
        <>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <div>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    searchedKeyword={searchedKeyword}
                    showSearch={true}
                    requestSearch={requestSearch}
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="lg" style={{marginTop:80}}>

                    <Grid container>
                        {subtitle()}
                        {showDesktopDataTable()}
                        {showMobileDataTable()}
                    </Grid>

                    {/*
                    <Fab style={{position:'fixed',bottom:26,right:26}} color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                    */}
                    
                </Container>
                <Footer />

            </div>

        </>        
    )
 
}