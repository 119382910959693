import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const monthlyDataSlice = createSlice({
    name: "monthlyData",
    initialState: {
        isLoading: false,
        monthlyData: []
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setMonthlyData: (state, action) => {
            state.monthlyData = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setMonthlyData
} = monthlyDataSlice.actions

export const getMonthlyDataList = (
    token, 
    factoryId, 
    selectedDate
) => async (dispatch) => {

    dispatch(startLoading())

    const splitUpDate = selectedDate.split("-")
    const year = splitUpDate[0]
    const month = splitUpDate[1]
    const day = splitUpDate[2]

    var getMonthlyData =
        apiGateway.get_monthly_readings(
            token, 
            factoryId,
            year,
            month,
            day
        )

    Promise.all([getMonthlyData])
        .then((values) => {

            dispatch(setMonthlyData(values[0]))
            dispatch(stopLoading())

        })

}

export const selectMonthlyDataList = (state) => state.monthlyData.monthlyData
export const selectMonthlyDataLoading = (state) => state.monthlyData.isLoading

export default monthlyDataSlice.reducer