import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../../api/Orwell'

const sensorSlice = createSlice({
    name: "sensor",
    initialState: {
        isLoading: false,
        sensors: [],
        copySensors: [],
        searchedKeyword: "",
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setSensors: (state, action) => {
            state.sensors = action.payload
        },
        setCopySensors: (state, action) => {
            state.copySensors = action.payload
        },
        setSearchedKeyword: (state, action) => {
            state.searchedKeyword = action.payload
        }        
    }
})

export const { 
    startLoading,
    stopLoading,
    setSensors,
    setCopySensors,
    setSearchedKeyword
} = sensorSlice.actions

export const getSensorList = (token, factoryId) => async (dispatch) => {

    dispatch(startLoading())

    var getSensorsById = 
        apiGateway.get_sensors(token, factoryId)

    Promise.all([getSensorsById])
        .then((values) => {

            const sensors = values[0]
            dispatch(setSensors(sensors))
            dispatch(setCopySensors(sensors))
            dispatch(stopLoading())

        })

}

export const getAllSensors = (token) => async (dispatch) => {

    dispatch(startLoading())

    var getSensors =
        apiGateway.get_all_sensor(token)

    Promise.all([getSensors])
        .then((values) => {

            const sensors = values[0]
            dispatch(setSensors(sensors))
            dispatch(setCopySensors(sensors))
            dispatch(stopLoading())

        })

}

export const selectIsLoading = (state) => state.sensor.isLoading
export const selectSensorList = (state) => state.sensor.sensors
export const selectCopySensorList = (state) => state.sensor.copySensors
export const selectSensorSearchedKeyword = (state) => state.sensor.searchedKeyword

export default sensorSlice.reducer