import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import TopAppBar from '../../ui/TopAppBar'
import Title from '../../ui/Title'
import SkeletonRoundedList from '../../ui/SkeletonRoundedList'
import CustomerDesktopTableList from '../../ui/CustomerDesktopTableList'
import CustomerMobileTableList from '../../ui/CustomerMobileTableList'
import { MobileView, isDesktop, isTablet } from 'react-device-detect'
import { selectToken } from '../../redux/features/security/authenticationSlice'
import { Container, Grid, LinearProgress } from '@mui/material'
import { selectCustomerSearchedKeyword, setCustomers, getCustomerList, selectCustomerList, selectCopyCustomerList, selectCustomerIsLoading, setSearchedKeyword } from './customerSlice'

export default function Customer() {

    const [open, setOpen] = useState(false)
    const [customersLoaded, setCustomersLoaded] = useState(false)

    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const customers = useSelector(selectCustomerList)
    const copyCustomers = useSelector(selectCopyCustomerList)
    const isLoading = useSelector(selectCustomerIsLoading)
    const searchedKeyword = useSelector(selectCustomerSearchedKeyword)    

    useEffect(() => {

        if (!customersLoaded && token !== '') {
            dispatch(getCustomerList(token))
            setCustomersLoaded(true)
        }

    }, [
        customersLoaded,
        dispatch,
        token
    ])

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const requestSearch = (searchedVal) => {
        
        const filteredRows = copyCustomers.filter((row) => {
            return row.email.toLowerCase().includes(searchedVal.toLowerCase())
        })

        dispatch(setSearchedKeyword(searchedVal))
        dispatch(setCustomers(filteredRows))
        
    }

    const subtitle = () => (
        <Grid item xs={12} md={10} lg={10}>
            <Title style={{paddingBottom:10}}>Customer</Title>
        </Grid>
    )

    const showDesktopDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                {isDesktop && !isTablet ?
                <>
                    {isLoading ? 
                    <SkeletonRoundedList /> : 
                    <CustomerDesktopTableList customers={customers} />
                    }
                </>
                : null }
            </Grid>
        </Grid>
    )

    const showMobileDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <MobileView>
                    {isLoading ? 
                    <SkeletonRoundedList /> : 
                    <CustomerMobileTableList customers={customers} />
                    }
                </MobileView>
            </Grid>
        </Grid>
    )

    return(
        <>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <>
                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    searchedKeyword={searchedKeyword}
                    showSearch={true}
                    requestSearch={requestSearch}
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="lg" style={{marginTop:80}}>

                    <Grid container>
                        {subtitle()}
                        {showDesktopDataTable()}
                        {showMobileDataTable()}
                    </Grid>

                    {/*
                    <Fab style={{position:'fixed',bottom:26,right:26}} color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                    */}
                    
                </Container>
                
                <Footer />

            </>

        </>
    )
 
}