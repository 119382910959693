import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from "moment"
import Title from '../../ui/Title'
import Footer from '../../ui/Footer'
import TopAppBar from '../../ui/TopAppBar'
import LeftDrawer from '../../ui/LeftDrawer'
import ReadingsChart from '../../ui/ReadingsChart'
import { Container, Grid, Typography, LinearProgress } from '@mui/material'
import { selectSensorList } from '../../redux/features/sensor/sensorSlice'
import { selectToken, selectAuthFactoryId } from '../../redux/features/security/authenticationSlice'
import { selectDatesCountList, getDatesCountList } from '../../redux/features/datesCount/datesCountSlice'
import { getYearlyDataList, selectYearlyDataList, selectYearlyDataLoading } from '../../redux/features/yearly/yearlyDataSlice'

export default function Yearly() {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [hasYearlyDataLoaded, setHasYearlyDataLoaded] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))

    const token = useSelector(selectToken)
    const authFactoryId = useSelector(selectAuthFactoryId)
    const availableDates = useSelector(selectDatesCountList)
    const sensors = useSelector(selectSensorList)
    const isLoading = useSelector(selectYearlyDataLoading)
    const yearlyData = useSelector(selectYearlyDataList)

    useEffect(() => {

        if (availableDates.length === 0)
            dispatch(getDatesCountList(token, authFactoryId))

        if (!hasYearlyDataLoaded) {

            dispatch(getYearlyDataList(token, authFactoryId, selectedDate))
            setHasYearlyDataLoaded(true)

        }

    }, [
        hasYearlyDataLoaded,
        dispatch,
        token,
        authFactoryId,
        availableDates,
        selectedDate
    ])

    const handleDateChange = date => {

        let selected_date = date.format("YYYY-MM-DD")
        setSelectedDate(selected_date)
        dispatch(getYearlyDataList(token, authFactoryId, selected_date))

    }

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    const disableDates = date => {
        var needle = date.format("YYYY-MM-DD");
        return (availableDates.indexOf(needle) > -1) ? false : true;
    }

    const sensorsCharts = () => (
        <Grid item xs={12} md={12} lg={12} sx={{marginTop:4}}>
            {sensors.map((key, i) => (
                <Grid key={i} item xs={12} md={12} lg={12} sx={{padding:1}}>
                    <ReadingsChart 
                        sensors={sensors} 
                        data={yearlyData} 
                        i={i}
                        format='MMM' />
                </Grid>
            ))}
        </Grid>
    )

    const subtitle = () => (
        <Grid item xs={12} md={10} lg={10}>
            <Title>Yearly readings</Title>
            <Typography variant="body2" component="span">
                Showing yearly average readings based on selected year.
                Please change the year accordingly.
            </Typography>
        </Grid>
    )

    return(
        <React.Fragment>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <div>

                {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    showYearChooser={true}
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    disableDates={disableDates}
                    availableDates={availableDates}
                    showNotification={false}
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="lg" style={{marginTop:80}}>

                    <Grid container>
                        {subtitle()}
                        {sensorsCharts()}
                    </Grid>

                </Container>
                <Footer />
            </div>

        </React.Fragment>
        
    )

}