import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

export default function Title(props) {
    return (
        <Typography component="h4" variant="h4" style={props.style}>
            {props.children}
        </Typography>
    )
}

Title.propTypes = {
    children: PropTypes.node,
}
