import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
// import IconButton from '@mui/material/IconButton'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HideOnScroll from './HideOnScroll'
import { Box } from '@mui/material'
import DeriaBiruLogo from './WhiteDeriaBiruLogo'
import HelpForgotPopover from './HelpForgotPopover'
// import { Link } from "react-router-dom"

export default function FrontTopAppBar(props) {
    
    return(
        <HideOnScroll>
        <AppBar position="fixed">
            <Toolbar variant="dense">

                {/*
                <IconButton
                    component={Link}
                    to="/"
                    edge="start"
                    color="inherit"
                    aria-label="back to home">
                    <ArrowBackIcon />
                </IconButton>            
                */}
                
                <Box style={{flexGrow:1}} />
                <DeriaBiruLogo />
                <Box style={{flexGrow:1}} />                

                <HelpForgotPopover />

            </Toolbar>
        </AppBar>
        </HideOnScroll>
    )

}
