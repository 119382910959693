import * as React from 'react'
import Box from '@mui/material/Box'
import { ReactComponent as DeriaBiruLogo } from '../images/db-blue.svg'

export default function LogoCentered() {
    
    return(
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            marginTop : 2 
        }}>
            <DeriaBiruLogo />
        </Box>
    )

}
