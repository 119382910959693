import * as React from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

export default function BasicPopover() {

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                aria-describedby={id}
                onClick={handleClick}
                edge="start"
                color="inherit"
                aria-label="help on reset password">
                <HelpOutlineIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Box sx={{padding:2}}>
                    <Typography variant="h6" sx={{padding:1}}>Resetting password</Typography>
                    <Typography variant="body2" sx={{padding:1}}>Make sure you're using the e-mail address registered to the previous Orwell site.</Typography>
                    <Typography variant="body2" sx={{padding:1}}>If you don't remember the e-mail address, please contact us at <a href="mailto:help@deriabiru.my">help@deriabiru.my</a></Typography>
                    <Typography variant="body2" sx={{padding:1}}>When setting the new password, please follow our latest password policy :-</Typography>
                    <ul>
                        <li><Typography variant="body2">Minimum length of 16 characters</Typography></li>
                        <li><Typography variant="body2">Must contain upper and lower case characters</Typography></li>
                        <li><Typography variant="body2">Must contain numbers &amp; special characters</Typography></li>
                    </ul>                    
                </Box>
            </Popover>
        </div>
    )
}
