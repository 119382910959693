import { useState, Fragment } from "react"
import { visuallyHidden } from '@mui/utils'
import * as moment from "moment"
import { stableSort, getComparator } from '../utils/Helper.js'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Box } from '@mui/material'

function CellCc({index, width, align, value}) {
    return(
        <TableCell width={width} key={index} align={align}>{value}</TableCell>
    )
}

function RowCc({points, sensor}) {

    return(
        <Fragment key={points.id}>
            <TableRow key={points.id}>                                
                <CellCc width="15%" align="right" index={points.id} value={points.avg_data+" "+sensor.unit} />
                <CellCc width="85%" align="left" index={points.id+1000} value={moment.unix(points.logtime).format('Do MMMM, YYYY hh:mm A')} />
            </TableRow>
        </Fragment>
    )

}

export default function ControlChartsDesktopTableList({cc, sensor}) {
    
    const [orderBy, setOrderBy] = useState('logtime')
    const [order, setOrder] = useState('asc')

    const desktopHeadCells = [        
        { avg_data: "avg_data", numeric: true, disablePadding: false, label: "Control data" },
        { id: "logtime", numeric: false, disablePadding: false, label: "Date time" },
    ]

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'
    
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }
    
    if (cc.length > 0) {

        return(
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {desktopHeadCells.map((headCell) => (
                                    <TableCell                                        
                                        key={headCell.id}
                                        align={headCell.numeric ? 'right' : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stableSort(cc, getComparator(order, orderBy))
                            .map((row, index) => {
                                return(<RowCc key={index} points={row} sensor={sensor} />)
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )

    } else {
        return("")
    }

}