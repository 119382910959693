import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

export default function Subtitle(props) {
    return (
        <Typography component="h6" variant="h6">
            {props.children}
        </Typography>
    )
}

Subtitle.propTypes = {
    children: PropTypes.node,
}
