import React from "react"
import TabPanel from './TabPanel'
import { Tabs, Tab, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function SensorMobileTable({
    sensors,
    sensorDisplayValue,
    handleSensorChange
}) {

    const a11yProps = (index) => {
        return {
            id  : `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        }
    }

    return(
        <>
            <Tabs
                value={sensorDisplayValue}
                onChange={handleSensorChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs">
                {sensors.map((n, index) => (
                    <Tab key={index} label={n.name} {...a11yProps(index)} />
                ))}
            </Tabs>
            <Paper sx={{marginTop:2}}>
                {sensors.map((n, index) => (
                <TabPanel value={sensorDisplayValue} index={index} key={index}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>Sensor Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                                
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">{n.name}</TableCell>
                            </TableRow>                                
                            <TableRow>
                                <TableCell>Unit</TableCell>
                                <TableCell align="right">{n.unit}</TableCell>
                            </TableRow>           
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell align="right">{n.type}</TableCell>
                            </TableRow>           
                            <TableRow>
                                <TableCell>GPIO</TableCell>
                                <TableCell align="right">{n.gpio}</TableCell>
                            </TableRow>  
                            <TableRow>
                                <TableCell>Min Raw</TableCell>
                                <TableCell align="right">{n.min_raw}</TableCell>
                            </TableRow>                                
                            <TableRow>
                                <TableCell>Max Raw</TableCell>
                                <TableCell align="right">{n.max_raw}</TableCell>
                            </TableRow>                                
                            <TableRow>
                                <TableCell>Min Rep</TableCell>
                                <TableCell align="right">{n.min_rep}</TableCell>
                            </TableRow>                                
                            <TableRow>
                                <TableCell>Max Rep</TableCell>
                                <TableCell align="right">{n.max_rep}</TableCell>
                            </TableRow>                                                                                                        
                        </TableBody>
                    </Table>
                </TabPanel>
                ))}
            </Paper>
        </>
    )

}