import React, { useEffect } from 'react'
import Footer from '../ui/Footer'
import HideOnScroll from '../ui/HideOnScroll'
import DeriaBiruLogo from '../ui/WhiteDeriaBiruLogo'
import { Container, Grid, Typography, AppBar, Toolbar, Box } from '@mui/material'

export default function FAQ() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return(
        <>

            <HideOnScroll>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <Box style={{flexGrow:1}} />
                        <DeriaBiruLogo />
                        <Box style={{flexGrow:1}} />
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Container maxWidth="md" style={{marginTop:80}}>

                <Grid container>
                    <Grid item xs={12} md={10} lg={10}>
                        <Typography variant="h4" component="h4" gutterBottom>
                        Frequently Asked Questions
                        </Typography>
                        <Typography variant="h5" color="gray" sx={{mb:5}}>
                        Deria Biru is a cloud data acquisition platform that comprises sensors and software services. To help you use these services efficiently, please take some time to read the following questions.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        How do I access the web site?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        First of all, you would have to subscribe to our services. Upon subscription, we will provide you the username and password to access the web site. Once you have the access, you would then be able to login to the web site and access your monitoring data.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        How do I reset my password?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        At the landing page of the web site, there is a tab called "Request new password". Click on the tab and fill in your username. We will then reset the password through an email that we will be sending to you.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        How do I add more sensor?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        Please call or send email to us. At the moment, we can only add any sensors upon your request because we would have to process it manually, to ensure the right setup and configuration.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        Can I connect any kind of sensor?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        Yes, we can connect to any kind of industrial sensors to our cloud data platform. Call or send email to us for enquiry.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        What can I do?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        Once you have the access to our website, you can monitor your daily data by choosing date, toggle between sensors, read information about the healthiness of the sensors and the data acquisition platform and configuring notifications.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                        What is the working hours?
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom color="gray" sx={{mb:5}}>
                        Our technical support is on standby from Monday to Friday, 9:00 AM to 5:00 PM. We could be reach at this email help@deriabiru.my.
                        </Typography>

                    </Grid>
                </Grid>

            </Container>

            <Footer />

        </>
    )

}
