import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Title from '../../ui/Title'
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import TopAppBar from '../../ui/TopAppBar'
import SkeletonRoundedList from '../../ui/SkeletonRoundedList'
import PersonIcon from '@mui/icons-material/Person'
import { Container, Grid, Paper, LinearProgress, Typography, Avatar } from '@mui/material'
import { selectToken, selectRole, selectEmail } from '../../redux/features/security/authenticationSlice'
import { selectFactory, selectCompany, selectDistributor, selectProfileIsLoading, getUserInfo } from '../../redux/features/profile/profileSlice'

export default function Profile() {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [hasUserInfoLoaded, setHasUserInfoLoaded] = useState(false)

    const token = useSelector(selectToken)
    const role = useSelector(selectRole)
    const email = useSelector(selectEmail)
    const factory = useSelector(selectFactory)
    const company = useSelector(selectCompany)
    const distributor = useSelector(selectDistributor)
    const isLoading = useSelector(selectProfileIsLoading)

    useEffect(() => {

        if (!hasUserInfoLoaded && token !== '') {
            dispatch(getUserInfo(token))
            setHasUserInfoLoaded(true)
        }

    }, [
        dispatch,
        hasUserInfoLoaded,
        token, 
        role
    ])

    const subtitle = () => (
        <Grid item xs={12} md={12} lg={12}>
            <Title>Profile</Title>
        </Grid>
    )

    const factoryCompanyInfo = () => (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Avatar sx={{color:'#ffffff',width:50,height:50,marginBottom:4,marginTop:-5}}>
                    <PersonIcon fontSize="large" />
                </Avatar>
                {emailField()}
            </Grid>
            {role === "4" ?            
            <React.Fragment>
                <Grid item xs={12} md={12} lg={12}>
                    {factoryField()}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {companyField()}
                </Grid>         
                <Grid item xs={12} md={12} lg={12}>
                    {distributorField()}
                </Grid>
            </React.Fragment>   
            : 
            role === "2" ?
            <React.Fragment>
                <Grid item xs={12} md={12} lg={12}>
                    {distributorField()}
                </Grid>
            </React.Fragment>   
            : 
            role === "1" || role === "3" ?
            <React.Fragment>
                <Grid item xs={12} md={12} lg={12}>
                    {systemAdminField()}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {companyField()}
                </Grid>     
            </React.Fragment>
            : null }
        </React.Fragment>
    )

    const systemAdminField = () => (
        <React.Fragment>
            <Typography variant="subtitle" color="gray">Role</Typography>
            <Typography variant="h6">{role === "1" ? <>System Administrator</> : <>Staff</>}</Typography>
        </React.Fragment>
    )

    const emailField = () => (
        <React.Fragment>
            <Typography variant="subtitle" color="gray">Email address</Typography>
            <Typography variant="h6">{email}</Typography>
        </React.Fragment>
    )

    const factoryField = () => (
        <React.Fragment>
            <Typography variant="subtitle" color="gray">Factory</Typography>
            <Typography variant="h6">{factory}</Typography>
        </React.Fragment>
    )

    const companyField = () => (
        <React.Fragment>
            <Typography variant="subtitle" color="gray">Company</Typography>
            <Typography variant="h6">{company}</Typography>
        </React.Fragment>
    )

    const distributorField = () => (
        <React.Fragment>
            <Typography variant="subtitle" color="gray">Distributor</Typography>
            <Typography variant="h6">{distributor}</Typography>
        </React.Fragment>
    )

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)

    return(
        <>

            <LeftDrawer
                handleDrawerClose={handleDrawerClose}
                open={open} />

            <>
            {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                <TopAppBar
                    handleDrawerOpen={handleDrawerOpen} />

                <Container maxWidth="sm" style={{marginTop:80}}>

                    {isLoading ? <SkeletonRoundedList /> :
                    <>
                        <Grid container spacing={2}>
                            {subtitle()}
                        </Grid>
                        <Paper sx={{padding:2,marginTop:3}}>
                            <Grid container spacing={2}>
                                {factoryCompanyInfo()}
                            </Grid>
                        </Paper>
                    </>
}
                </Container>

                <Footer />                

            </>

        </>
    )

}