import { useState, Fragment } from "react"
import { visuallyHidden } from '@mui/utils'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { stableSort, getComparator } from '../utils/Helper.js'
import { Chip, Collapse, Paper, TableBody, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'

function Row({sensor}) {

    const [open, setOpen] = useState(false)

    return(
        <Fragment key={sensor.id}>
            <TableRow hover>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <div><strong>{sensor.name}</strong></div>
                    <div>{sensor.factory}</div>                    
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0}}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0}}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{marginBottom:10}}>
                        <Chip label={"MinRaw: " + sensor.min_raw} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"MaxRaw: " + sensor.max_raw} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"MinRep: " + sensor.min_rep} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"MaxRep: " + sensor.max_rep} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"MaxLimit: " + sensor.max_limit} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"IO: " + sensor.gpio} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"Type: " + sensor.type} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"Unit: " + sensor.unit} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                        <Chip label={"Stack: " + sensor.stack} variant="outlined" color="primary" sx={{marginRight:1,marginBottom:1}} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )

}

export default function SensorMobileTableList({sensors}) {

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')

    const mobileHeadCells = [
        { id: "collapse", numeric: false, disablePadding: false, label: "" },
        { id: "name", numeric: false, disablePadding: false, label: "Name" },
    ]

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        
    }
    
    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        {mobileHeadCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            )
                        )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(sensors, getComparator(order, orderBy))
                        .map((row, index) => {
                            return(<Row key={index} sensor={row} />)
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}