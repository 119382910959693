import React from 'react'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function SensorDesktopTable({sensors}) {

    return(
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Sensor</TableCell>
                        <TableCell align="center">Unit</TableCell>
                        <TableCell align="center">Type</TableCell>
                        <TableCell align="center">GPIO</TableCell>
                        <TableCell align="center">MinRaw</TableCell>
                        <TableCell align="center">MaxRaw</TableCell>
                        <TableCell align="center">MinRep</TableCell>
                        <TableCell align="center">MaxRep</TableCell>
                        <TableCell align="center">MaxLimit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {sensors.map((n, index) => (
                    <TableRow key={index}>
                        <TableCell component="th" scope="row">
                        {n.name}
                        </TableCell>
                        <TableCell align="center">{n.unit}</TableCell>
                        <TableCell align="center">{n.type}</TableCell>
                        <TableCell align="center">{n.gpio}</TableCell>
                        <TableCell align="center">{n.min_raw}</TableCell>
                        <TableCell align="center">{n.max_raw}</TableCell>
                        <TableCell align="center">{n.min_rep}</TableCell>
                        <TableCell align="center">{n.max_rep}</TableCell>
                        <TableCell align="center">{n.max_limit}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Paper>
    )

}