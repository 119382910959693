import { useState } from "react"
import { visuallyHidden } from '@mui/utils'
import { stableSort, getComparator } from '../utils/Helper.js'
import { TableBody, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'

export default function SensorDesktopTableList({sensors}) {

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')

    const desktopHeadCells = [
        { id: "name", numeric: false, disablePadding: false, label: "Name" },
        { id: "unit", numeric: false, disablePadding: false, label: "Unit" },
        { id: "stack", numeric: true, disablePadding: false, label: "Stack" },
        { id: "gpio", numeric: true, disablePadding: false, label: "GPIO" },
        { id: "min_raw", numeric: true, disablePadding: false, label: "MinRaw" },
        { id: "max_raw", numeric: true, disablePadding: false, label: "MaxRaw" },
        { id: "min_rep", numeric: true, disablePadding: false, label: "MinRep" },
        { id: "max_rep", numeric: true, disablePadding: false, label: "MaxRep" },
        { id: "max_limit", numeric: true, disablePadding: false, label: "MaxLimit" },
    ]

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const handleRequestSort = (event, property) => {

        const isAsc =
            orderBy === property &&
            order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        
    }

    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        {desktopHeadCells.map((headCell, index) => (
                            <TableCell
                                key={index}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            )
                        )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(sensors, getComparator(order, orderBy))
                        .map((row, index) => {
                            return(
                            <TableRow
                                hover
                                key={index}>
                                <TableCell component="th" scope="row">
                                    <div><strong>{row.name}</strong></div>
                                    <div>{row.factory}</div>
                                </TableCell>
                                <TableCell>{row.unit}</TableCell>
                                <TableCell align="right">{row.stack}</TableCell>
                                <TableCell align="right">{row.gpio}</TableCell>
                                <TableCell align="right">{row.min_raw}</TableCell>
                                <TableCell align="right">{row.max_raw}</TableCell>
                                <TableCell align="right">{row.min_rep}</TableCell>
                                <TableCell align="right">{row.max_rep}</TableCell>
                                <TableCell align="right">{row.max_limit}</TableCell>
                            </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}