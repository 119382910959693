import { createSlice } from '@reduxjs/toolkit'
import { apiGateway } from '../../api/Orwell'

const distributorSlice = createSlice({
    name: "distributor",
    initialState: {
        isLoading: false,
        distributorList: [],
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
        setDistributors: (state, action) => {
            state.distributorList = action.payload
        }
    }
})

export const {
    startLoading,
    stopLoading,
    setDistributors,
} = distributorSlice.actions

export const getDistributorList = (token) => async (dispatch) => {

    dispatch(startLoading())

    var getDistributos = apiGateway.get_all_distributor(token)

    Promise.all([getDistributos])
        .then((values) => {

            const distributors = values[0]
            dispatch(setDistributors(distributors))
            dispatch(stopLoading())

        })

}

export const selectDistributorIsLoading = (state) => state.distributor.isLoading
export const selectDistributorList = (state) => state.distributor.distributorList

export default distributorSlice.reducer