import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import useMqtt from '../../hooks/useMqtt'
import Footer from '../../ui/Footer'
import LeftDrawer from '../../ui/LeftDrawer'
import TopAppBar from '../../ui/TopAppBar'
import Title from "../../ui/Title"
import FactoryMobileTableList from "../../ui/FactoryMobileTableList"
import FactoryDesktopTableList from "../../ui/FactoryDesktopTableList"
import SkeletonRoundedList from '../../ui/SkeletonRoundedList'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { ToggleButton, ToggleButtonGroup, Container, Grid, LinearProgress } from '@mui/material'
import { MobileView, isDesktop, isTablet } from 'react-device-detect'
import { selectToken } from '../../redux/features/security/authenticationSlice'
import { selectToggleSelectedIndex, setToggleSelectedIndex, selectIsLoading, setFactories, getFactoryList, selectFactoryList, selectCopyFactoryList, selectSearchedKeyword, setSearchedKeyword } from './factorySlice'

export default function Factory() {

    const { mqttSubscribe, mqttConnect, isConnected, payload } = useMqtt()

    const [open, setOpen] = useState(false)
    const [factoryLoaded, setFactoryLoaded] = useState(false)
    const [factoryDetails, setFactoryDetails] = useState({})
    const [redirect, setRedirect] = useState(false)
    const toggleButtonValue = useSelector(selectToggleSelectedIndex)

    const token = useSelector(selectToken)
    const isLoading = useSelector(selectIsLoading)
    const factories = useSelector(selectFactoryList)
    const copyFactories = useSelector(selectCopyFactoryList)
    const searchedKeyword = useSelector(selectSearchedKeyword)
    const [connectedClients, setConnectedList] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        
        if (!factoryLoaded && factories.length === 0 && !isLoading && token !== '') {
            setFactoryLoaded(true)
            dispatch(getFactoryList(token))
            mqttConnect()
        }

    }, [
        factories, 
        copyFactories, 
        factoryLoaded, 
        isLoading, 
        dispatch,
        token,
        mqttConnect
    ])

    useEffect(() => {

        if (isConnected) {
            mqttSubscribe("status/#")
        }

    }, [isConnected, mqttSubscribe])    

    useEffect(() => {
        
        if (payload.message) {

            const client = JSON.parse(payload.message.toString())
            const schema = client["schema"]
            const index = connectedClients.indexOf(schema)            

            if (client["status"] === 1) {
            
                // If client is not in the list, add it.
                if (index === -1) {
                    setConnectedList([...connectedClients, schema])
                    enqueueSnackbar(`${schema} is connected`, { variant: 'success' })                    
                }

            } else {
                
                // If client is in the list, remove it.
                if (index > -1) {
                    setConnectedList(connectedClients.filter((item) => item !== schema))
                    enqueueSnackbar(`${schema} is disconnected`, { variant: 'error' })
                }
                
            }

        }

    }, [payload, connectedClients, dispatch, token])

    useEffect(() => {
        
        if (factories.length > 0) {
            
            factories.forEach((factory) => {
                if (factory.connected) {
                    connectedClients.push(factory.schema)
                }
            
            })
            
        }

    }, [factories, connectedClients])

    const handleDrawerOpen = () => setOpen(true)

    const handleDrawerClose = () => setOpen(false)    

    const handleClick = (event, row) => {
        
        setFactoryDetails(row)
        setRedirect(true)

    }

    const handleToggleButton = (event, value) => {
        
        dispatch(setToggleSelectedIndex(value))

        switch (value) {

            case "all":
                dispatch(setFactories(copyFactories))
                break

            case "online":
                const onlineFactories = copyFactories.filter(factory => connectedClients.includes(factory.schema))
                dispatch(setFactories(onlineFactories))
                break

            case "offline":
                const offlineFactories = copyFactories.filter(factory => !connectedClients.includes(factory.schema))
                dispatch(setFactories(offlineFactories))
                break

            default:
                break

        }

    }

    const requestSearch = (searchedVal) => {

        const filteredRows = copyFactories.filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.company.toLowerCase().includes(searchedVal.toLowerCase())
        })
        
        dispatch(setSearchedKeyword(searchedVal))
        dispatch(setFactories(filteredRows))
        dispatch(setToggleSelectedIndex("all"))

    }

    const showDesktopDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                {isDesktop && !isTablet ?
                <div>
                    {isLoading ? 
                    <SkeletonRoundedList /> :
                    <FactoryDesktopTableList 
                        factories={factories}
                        connectedClients={connectedClients}
                        handleClick={handleClick} />
                    }
                </div>
                : null }
            </Grid>
        </Grid>
    )

    const showMobileDataTable = () => (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <MobileView>
                <>
                    { isLoading ? 
                        <SkeletonRoundedList /> : 
                        <FactoryMobileTableList 
                            factories={factories}
                            connectedClients={connectedClients}
                            handleClick={handleClick} /> 
                    }
                </>
                </MobileView>
            </Grid>
        </Grid>
    )

    if (redirect) {

        return <Navigate push
            to={"/admin/factory/details"}
            state={{factory:factoryDetails,reload:true}} />

    } else {

        return(
            <SnackbarProvider maxSnack={3}>

                <LeftDrawer
                    handleDrawerClose={handleDrawerClose}
                    open={open} />

                <div>
                    {isLoading ? <LinearProgress style={{zIndex:1251}} /> : null}

                    <TopAppBar
                        searchedKeyword={searchedKeyword}
                        showSearch={true}
                        requestSearch={requestSearch}
                        handleDrawerOpen={handleDrawerOpen} />

                    <Container maxWidth="lg" style={{marginTop:80}}>

                        <Grid container>
                            <Grid item xs={12} md={10} lg={12} style={{marginBottom:10}}>
                                <Title style={{paddingBottom:10}}>Factory</Title>
                                <ToggleButtonGroup
                                    color='primary'
                                    value={toggleButtonValue}
                                    onChange={handleToggleButton}
                                    exclusive
                                    aria-label="text alignment"
                                    fullWidth
                                    size="small">
                                    <ToggleButton value="all" aria-label="centered">All</ToggleButton>
                                    <ToggleButton value="online" aria-label="centered">Online</ToggleButton>
                                    <ToggleButton value="offline" aria-label="centered">Offline</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {showDesktopDataTable()}
                            {showMobileDataTable()}
                        </Grid>

                        {/*
                        <Fab style={{position:'fixed',bottom:26,right:26}} color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                        */}

                    </Container>
                    <Footer />

                </div>                    

            </SnackbarProvider>
        )

    }

}
