import React, { useState } from 'react'
import * as moment from "moment"
import { visuallyHidden } from '@mui/utils'
import { stableSort, getComparator } from '../utils/Helper.js'
import { ReactComponent as RedLED } from '../images/led-red.svg'
import { ReactComponent as GreenLED } from '../images/led-green.svg'
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'

export default function FactoryDesktopTableList({
    factories, 
    connectedClients, 
    handleClick, 
    ...props
    }) {

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "Name" },
        { id: "doe_id", numeric: false, disablePadding: false, label: "DoE ID" },
        { id: "status", numeric: false, disablePadding: false, label: "Status" },
        { id: "ctime", numeric: false, disablePadding: false, label: "Joined" },
    ]
    
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')

    const createSortHandler = (property) => () => handleRequestSort(property)

    const handleRequestSort = (property) => {

        const isAsc =
            orderBy === property && order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)

    }

    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                            )
                        )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {stableSort(factories, getComparator(order, orderBy))
                        .map((row, index) => {
                            return(
                            <TableRow
                                hover
                                key={row.id}
                                onClick={(event) => handleClick(event, row)}>
                                <TableCell component="th" scope="row">
                                    <div>
                                        {connectedClients.includes(row.schema) ? <GreenLED style={{marginRight:10}} /> : <RedLED style={{marginRight:10}} />}
                                        <strong>{row.name}</strong>
                                    </div>
                                    <div style={{marginLeft:20}}>{row.company}</div>
                                </TableCell>
                                <TableCell>{row.doe_id}</TableCell>
                                <TableCell>{row.status ? "Active" : "Inactive" }</TableCell>
                                <TableCell>{moment.unix(row.ctime).format('DD/MM/YYYY')}</TableCell>
                            </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

}